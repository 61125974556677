import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-asesor',
  templateUrl: './asesor.component.html',
  styleUrls: ['./asesor.component.css']
})
export class AsesorComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
