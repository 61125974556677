import { Component, OnInit, ɵConsole, Input } from '@angular/core';
import { Publicacion } from '../../../models/post';
import { ArticuloService } from '../../../services/articulo.service';
import { AlertifyService } from '../../../services/alertify.service';
import { Global } from '../../../services/global';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import 'node_modules/froala-editor/js/languages/es.js';

@Component({
  selector: 'app-articulo-nuevo',
  templateUrl: './articulo-nuevo.component.html',
  styleUrls: ['./articulo-nuevo.component.css'],
  providers: [ArticuloService]
})
export class ArticuloNuevoComponent implements OnInit {

  publicacions: any = {};
  url: any;

  sendData: FormGroup;

  formData: FormData;
  // @Input() publicacion: Publicacion;
  // public publicacion: Publicacion;
  // Post: any = [];

  // tslint:disable-next-line: ban-types
  public froalaOptions: Object = {
    charCounterCount: true,
    pluginsDisable: ['quickInsert'],
    toolbarButtons: ['bold', 'italic', 'underline', 'paragraphFormat', 'alert' ],
    toolbarButtonsXS: ['bold', 'italic', 'underline', 'paragraphFormat', 'alert'],
    toolbarButtonsSM: ['bold', 'italic', 'underline', 'paragraphFormat', 'alert'],
    toolbarButtonsMD: ['bold', 'italic', 'underline', 'paragraphFormat', 'alert'],
    language: 'es'
  };

  constructor(private articuloServices: ArticuloService,
              private alertify: AlertifyService, public formBuilder: FormBuilder) {
    this.formData = new FormData();
    this.sendData = this.formBuilder.group({
      titulo: [''],
      contenido: [''],
      imagen: null
    });
  }

  ngOnInit() {
  }

  onSubmit() {

    this.formData.append('titulo', this.sendData.get('titulo').value);
    this.formData.append('contenido', this.sendData.get('contenido').value);

    this.articuloServices.crear(this.formData).subscribe(
      response => {
        this.publicacions = response;
        this.alertify.success('Articulo creado correctamente');

      },
      error => {
        this.alertify.error('Error en los datos de articulo...');
      }
    );
  }
  readUrl(event: any) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();

      reader.onload = (event: ProgressEvent) => {
        this.url = (event.target as FileReader).result;
      };

      reader.readAsDataURL(event.target.files[0]);

      this.formData.append("imagen", event.target.files[0], event.target.files[0].name);

    }

  }
}

