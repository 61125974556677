import { Component, OnInit } from "@angular/core";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import * as $ from "jquery";
import { HttpClient } from "@angular/common/http";
import * as alertify from "alertifyjs";
import { AlertifyService } from "../services/alertify.service";
import { AuthService } from "../services/auth.service";
import { Router, ActivatedRoute, Params } from "@angular/router";

@Component({
  // tslint:disable-next-line: component-selector
  selector: "main-menu",
  templateUrl: "./main-menu.component.html",
  styleUrls: ["./main-menu.component.css"],
})
export class MainMenuComponent {
  offsetTop: any;
  values: any;
  yearAct: any;
  public searchString: any;

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map((result) => result.matches));

  constructor(
    private breakpointObserver: BreakpointObserver,
    private http: HttpClient,
    private route: ActivatedRoute,
    private alertify: AlertifyService,
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit() {
    $(document).ready(function () {
      $(".ir-arriba").click(function () {
        $("body, html").animate(
          {
            scrollTop: "0px",
          },
          300
        );
      });

      $(window).scroll(function () {
        if ($(this).scrollTop() > 0) {
          $(".ir-arriba").slideDown(300);
        } else {
          $(".ir-arriba").slideUp(300);
        }
      });
    });
  }
  buscar() {
    this.router.navigate(["/busqueda", this.searchString]);
  }
  logueado() {
    return this.authService.logeado();
  }
  logout() {
    localStorage.removeItem("token");
    this.alertify.message("Regresa pronto!");
    this.router.navigate(["/home"]);
  }
}
