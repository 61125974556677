import { ProyectosComponent } from './components/proyectos/proyectos.component';
import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
// componentes
import { LoginComponent } from './components/login/login.component';

// masillas
import { InterioresComponent } from './components/productos/masillas/interiores/interiores.component';
import { MasillaPRComponent } from './components/productos/masillas/interiores/masilla-pr/masilla-pr.component';
import { ExterioresComponent } from './components/productos/masillas/exteriores/exteriores.component';
import { PinturasComponent } from './components/productos/pinturas/pinturas.component';
import { RegistroComponent } from './components/distribuidores/registro/registro.component';
import { RegistroinsComponent } from './components/instaladores/registro/registro.component';
import { AdhesivosComponent } from './components/productos/adhesivos/adhesivos.component';
import { AdvancedComponent } from './components/productos/masillas/interiores/advanced/advanced.component';
import { BasickComponent } from './components/productos/masillas/interiores/basick/basick.component';
import { ProfessionalComponent } from './components/productos/masillas/interiores/professional/professional.component';
import { MaxComponent } from './components/productos/masillas/interiores/max/max.component';

// placas
import { LightEstandarComponent } from './components/productos/placas/panelrey/light-estandar/light-estandar.component';
import { GuardReyComponent } from './components/productos/placas/panelrey/guard-rey/guard-rey.component';
import { FireReyComponent } from './components/productos/placas/panelrey/fire-rey/fire-rey.component';
import { GlassReyComponent } from './components/productos/placas/panelrey/glass-rey/glass-rey.component';
import { RegularComponent } from './components/productos/placas/panelrey/regular/regular.component';
// publicos

import { QuienessomosComponent } from './components/quienessomos/quienessomos.component';
import { ResponsabilidadComponent } from './components/responsabilidad/responsabilidad.component';
import { BlogComponent } from './components/blog/blog.component';
import { TiposdeestucoComponent } from './components/blog/tiposdeestuco/tiposdeestuco.component';

import { HomeComponent } from './components/home/home.component';

import { PanelreyComponent } from './components/productos/placas/panelrey/panelrey.component';
import { PermabaseComponent } from './components/productos/placas/permabase/permabase.component';
import { MapaComponent } from './components/distribuidores/mapa/mapa.component';
import { CalculadoraComponent } from './components/instaladores/calculadora/calculadora.component';
import { AsesoriaComponent } from './components/instaladores/asesoria/asesoria.component';
import { CapacitacionComponent } from './components/instaladores/capacitacion/capacitacion.component';

import { ArticuloComponent } from './components/articulo/articulo.component';

// admin
import { GeneralComponent } from './components/productos/general/general.component';
// tslint:disable-next-line: max-line-length
import { MasillasFinishTeckComponent } from './components/productos/masillas/interiores/masillas-finish-teck/masillas-finish-teck.component';
import { MasillasHighProComponent } from './components/productos/masillas/interiores/masillas-high-pro/masillas-high-pro.component';


import { from } from 'rxjs';
import { MasillaparaparedesComponent } from './components/blog/masillaparaparedes/masillaparaparedes.component';
import { MasillaparadrywallComponent } from './components/blog/masillaparadrywall/masillaparadrywall.component';
// tslint:disable-next-line: max-line-length
import { DiferenciasentreestucoymasillaComponent } from './components/blog/diferenciasentreestucoymasilla/diferenciasentreestucoymasilla.component';
import { CertificaciongreenguardComponent } from './components/blog/certificaciongreenguard/certificaciongreenguard.component';
import { MiembrosComponent } from './components/adm/miembros/miembros.component';
import { BusquedaComponent } from './components/adm/busqueda/busqueda.component';
import { ArticuloNuevoComponent } from './components/adm/articulo-nuevo/articulo-nuevo.component';
import { UsuariosComponent } from './components/adm/mercadeo/usuarios/usuarios.component';
import { InscripcionComponent } from './inscripcion/inscripcion.component';



// rutas
const appRoutes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'home', component: HomeComponent },
  { path: 'inscripcion', component: InscripcionComponent },
  { path: 'login', component: LoginComponent, data: { animation: 'login' } },
  { path: 'registroinstalador', component: RegistroinsComponent },
  { path: 'registrodistribuidor', component: RegistroComponent },
  { path: 'productos', component: GeneralComponent, data: { animation: 'productos' } },
  { path: 'interiores', component: InterioresComponent },
  { path: 'exteriores', component: ExterioresComponent },
  { path: 'pr', component: MasillaPRComponent },
  { path: 'advanced', component: AdvancedComponent },
  { path: 'basick', component: BasickComponent },
  { path: 'professional', component: ProfessionalComponent },
  { path: 'max', component: MaxComponent },
  { path: 'adhesivos', component: AdhesivosComponent },
  { path: 'pinturas', component: PinturasComponent },
  { path: 'panelrey', component: PanelreyComponent },
  { path: 'permabase', component: PermabaseComponent },
  { path: 'finishteck', component: MasillasFinishTeckComponent },
  { path: 'highpro', component: MasillasHighProComponent },
  { path: 'ligthestandar', component: LightEstandarComponent },
  { path: 'guardrey', component: GuardReyComponent },
  { path: 'firerey', component: FireReyComponent },
  { path: 'glassrey', component: GlassReyComponent },
  { path: 'regular', component: RegularComponent },
  { path: 'proyectos', component: ProyectosComponent },
  { path: 'registro', component: RegistroComponent },
  { path: 'mapa', component: MapaComponent },
  { path: 'calcula', component: CalculadoraComponent },
  { path: 'asesoria', component: AsesoriaComponent },
  { path: 'capacitacion', component: CapacitacionComponent },
  { path: 'blog', component: BlogComponent },
  { path: 'articulo/:id', component: ArticuloComponent },
  { path: 'quienessomos', component: QuienessomosComponent },

  { path: 'responsabilidadsocial', component: ResponsabilidadComponent },
  { path: 'tiposdeestuco', component: TiposdeestucoComponent },
  { path: 'masillaparaparedes', component: MasillaparaparedesComponent },
  { path: 'masillaparadrywall', component: MasillaparadrywallComponent },
  { path: 'diferenciasentreestucoymasilla', component: DiferenciasentreestucoymasillaComponent },
  { path: 'certificaciongreenguard', component: CertificaciongreenguardComponent },
  { path: 'busqueda/:search', component: BusquedaComponent },
  // rutas protegidas
  {
    path: '',
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
    children: [
      { path: 'miembros', component: MiembrosComponent },
      { path: 'creararticulo', component: ArticuloNuevoComponent },
      { path: 'gestion-usuarios', component: UsuariosComponent }
    ]
  },

  { path: '**', redirectTo: '', pathMatch: 'full' },
];
// exportando
export const appRoutingProviders: any[] = [];
export const routing: ModuleWithProviders = RouterModule.forRoot(appRoutes);
