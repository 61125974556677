import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-quienessomos',
  templateUrl: './quienessomos.component.html',
  styleUrls: ['./quienessomos.component.css']
})
export class QuienessomosComponent implements OnInit {
  public docSstv5: string
  public docAdv3: string
  public docSegvialv2: string
  public docPrivacidad: string
  public docDatosPersonales: string

  constructor() {
  }

  ngOnInit() {
    this.docSstv5 = '../../assets/politica/politica-sst-v5-dic-2020.pdf'
    this.docAdv3 = '../../assets/politica/politica-prev-ad-v3-mar-2019-corta.pdf'
    this.docSegvialv2 = '../../assets/politica/politica-seg-vial-v2-dic-2018.pdf'
    this.docPrivacidad = '../../assets/politica/aviso-de-privacidad.pdf'
    this.docDatosPersonales = '../../assets/politica/politica-tratamiento-datos-personales.pdf'
  }
}
