import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-masilla-pr',
  templateUrl: './masilla-pr.component.html',
  styleUrls: ['./masilla-pr.component.css']
})
export class MasillaPRComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
