import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { pipe } from "rxjs";
import { map } from "rxjs/operators";
import { JwtHelperService } from "@auth0/angular-jwt";
import { Global } from "./global";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  baseUrl = Global.url + "Usuarios/";
  jwtHelper = new JwtHelperService();
  decodedToken: any;

  constructor(private http: HttpClient) {}
  login(model: any) {
    return this.http.post(this.baseUrl + "Login", model).pipe(
      map((response: any) => {
        const usuario = response;
        if (usuario) {
          localStorage.setItem("token", usuario.token);
          this.decodedToken = this.jwtHelper.decodeToken(usuario.token);
          // console.log(this.decodedToken);
        }
      })
    );
  }
  crear(model: any) {
    return this.http.post(this.baseUrl + "Crear", model);
  }
  logeado() {
    const token = localStorage.getItem("token");
    return !this.jwtHelper.isTokenExpired(token);
  }
}
