import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-asesores',
  templateUrl: './asesores.component.html',
  styleUrls: ['./asesores.component.css']
})
export class AsesoresComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
