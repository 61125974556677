import { Component, OnInit } from "@angular/core";
import { AuthService } from "../../../services/auth.service";
import * as alertify from "alertifyjs";
import { AlertifyService } from "../../../services/alertify.service";

@Component({
  selector: "app-capacitacion",
  templateUrl: "./capacitacion.component.html",
  styleUrls: ["./capacitacion.component.css"],
})
export class CapacitacionComponent implements OnInit {
  model: any = {};

  constructor(
    private authService: AuthService,
    private alertify: AlertifyService
  ) {}

  ngOnInit() {
    // disposicion previa del rol de usuario
    // 7 es "instalador"
    this.model.idrol = 7;
  }

  login() {
    this.authService.login(this.model).subscribe(
      (next) => {
        this.alertify.success("Autenticado correctamente!");
      },
      (error) => {
        this.alertify.error(
          "Error de autenticacion, recibira un correo confirmandole! "
        );
      }
    );
  }

  crear() {
    this.authService.crear(this.model).subscribe(
      (next) => {
        this.alertify.success(
          "registro exitoso, pronto nos pondremos en contacto!"
        );
      },
      (error) => {
        this.alertify.error(
          "¡Verifique que los datos insertados sean correctos y/o que su cuenta ya no se encuentre registrada! "
        );
        console.log(this.model);
      }
    );
  }
}
