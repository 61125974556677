import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dash',
  templateUrl: './dash.component.html',
  styleUrls: ['./dash.component.css']
})
export class DashdisComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
