import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { AlertifyService } from '../../../services/alertify.service';

@Component({
  selector: 'app-registro',
  templateUrl: './registro.component.html',
  styleUrls: ['./registro.component.css']
})
export class RegistroinsComponent implements OnInit {
  model: any = {};
  constructor(private authService: AuthService,
    private alertify: AlertifyService) { }

  ngOnInit() { }
  login() {
    this.authService.login(this.model).subscribe(next => {
      this.alertify.success('Autenticado correctamente!');
    }, error => {
      this.alertify.error('Error de autenticacion!');
    });
  }
  crear() {
    this.authService.crear(this.model).subscribe(next => {
      this.alertify.success('Registro exitoso, pronto nos pondremos en contacto!');
    }, error => {
      this.alertify.error('Error en el registro! ');
    });
  }
}
