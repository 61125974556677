import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-masillaparaparedes',
  templateUrl: './masillaparaparedes.component.html',
  styleUrls: ['./masillaparaparedes.component.css']
})
export class MasillaparaparedesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
