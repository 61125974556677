import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-exteriores',
  templateUrl: './exteriores.component.html',
  styleUrls: ['./exteriores.component.css']
})
export class ExterioresComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
