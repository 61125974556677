import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-basick',
  templateUrl: './basick.component.html',
  styleUrls: ['./basick.component.css']
})
export class BasickComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
