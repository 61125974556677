import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-curso',
  templateUrl: './curso.component.html',
  styleUrls: ['./curso.component.css']
})
export class CursoiComponent implements OnInit {
  constructor() {}

  ngOnInit() {}

  selectedDate() {}
}
