import { Component, OnInit, Input } from '@angular/core';
import { Publicacion } from '../../models/post';
import { ArticuloService } from '../../services/articulo.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AlertifyService } from '../../services/alertify.service';
import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { Global } from '../../services/global';
import { AuthService } from '../../services/auth.service';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-articulo',
  templateUrl: './articulo.component.html',
  styleUrls: ['./articulo.component.css'],
  providers: [ArticuloService]
})
export class ArticuloComponent implements OnInit {
  model: any = {};
  public url: string;
  publicacions: Publicacion[];
  @Input() articles: Publicacion[];
  constructor(
    private articuloService: ArticuloService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private sanitizer: DomSanitizer,
    private alertify: AlertifyService
  ) {
    this.url = Global.url;
  }

  public getSantizeUrl(url : string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
}

  ngOnInit() {
    this.route.params.subscribe(params => {
      const id = params['id'];

      this.articuloService.tomarArticulo(id).subscribe(
        response => {
          if (response) {
            this.publicacions = response;
          } else {
            this.router.navigate(['/home']);
          }
        }, error => {
          console.log(error.message);
          this.alertify.error('Articulo inexistente!');
          this.router.navigate(['/home']);
        }
      );
    });
  }
  logueado() {
    return this.authService.logeado();
  }

}
