import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fire-rey',
  templateUrl: './fire-rey.component.html',
  styleUrls: ['./fire-rey.component.css']
})
export class FireReyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
