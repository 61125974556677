import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-masillas-finish-teck',
  templateUrl: './masillas-finish-teck.component.html',
  styleUrls: ['./masillas-finish-teck.component.css']
})
export class MasillasFinishTeckComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
