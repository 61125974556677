import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-certificaciongreenguard',
  templateUrl: './certificaciongreenguard.component.html',
  styleUrls: ['./certificaciongreenguard.component.css']
})
export class CertificaciongreenguardComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
