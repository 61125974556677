import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Publicacion } from '../../../models/post';
import { ArticuloService } from '../../../services/articulo.service';
import { timeout } from 'q';
import { AuthService } from '../../../services/auth.service';
import { AlertifyService } from '../../../services/alertify.service';
import { Global } from 'src/app/services/global';


@Component({
  selector: 'app-busqueda',
  templateUrl: './busqueda.component.html',
  styleUrls: ['./busqueda.component.css'],
  providers: [ArticuloService]
})
export class BusquedaComponent implements OnInit {
  publications: any;
  public articulos: Publicacion[];
  public search: string;
  public url: string;
  constructor(private router: Router,
    private route: ActivatedRoute,
    private articuloService: ArticuloService,
    private authService: AuthService,
    private alertify: AlertifyService) {
      this.url = Global.url;
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      var search = params['search'];
      this.search = search;
      this.articuloService.busqueda(search).subscribe(
        response => {
          if (response) {

            this.articulos = response;
          } 1000
        },
        error => {
          this.articulos = [];
          this.router.navigate(['/home']); this.alertify.error('No hay articulos que coincidan!');
        }
      );
    });
  }
  logueado() {
    return this.authService.logeado();
  }

}
