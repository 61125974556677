import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-masillas-high-pro',
  templateUrl: './masillas-high-pro.component.html',
  styleUrls: ['./masillas-high-pro.component.css']
})
export class MasillasHighProComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
