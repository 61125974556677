import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-panelrey',
  templateUrl: './panelrey.component.html',
  styleUrls: ['./panelrey.component.css']
})
export class PanelreyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
