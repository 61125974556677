import { Component, OnInit } from '@angular/core';
declare let $: any;
@Component({
  selector: 'app-calculadora',
  templateUrl: './calculadora.component.html',
  styleUrls: ['./calculadora.component.css']
})
export class CalculadoraComponent implements OnInit {
  public metroj: number;
  public metrojAdvanced: number;
  public metroe: number;
  public metroAdvanced: number;
  public metrop: number;
  public metroad: number;
  public metropl: number;
  public metropa: number;
  public metrofi: number;
  public resultadoj: number;
  public resultadoe: number;
  public resultadoAdvanced: number;
  public resultadojAdvanced: number;
  public resultadop: number;
  public resultadoad: number;
  public resultadopl: number;
  public resultadopa: number;
  public resultadofi: number;
  constructor() { }

  ngOnInit() {
    const tipo = '';
  }

  juntas() {
    //fibro cemento
    this.resultadoj = 0.62 * this.metroj;
  }
  juntasAdvanced() {
    this.resultadojAdvanced = 0.37 * this.metrojAdvanced;
  }
  panete() {
    //pañete
    this.resultadopa = 0.97 / this.metropa;
  }
  estuco() {
    this.resultadoe = 1.86 * this.metroe;
  }
  estucoAdvanced() {
    this.resultadoAdvanced = 1.02 * this.metroAdvanced;
  }
  pintura() {
    this.resultadop = this.metrop / 30;
  }
  ad() {
    this.resultadoad = 0.69 * this.metroad;
  }
  placa() {
    this.resultadopl = this.metropl / 2.9768;
  }
  fibrocemento() {
    this.resultadofi = this.metrofi * 0.6;
  }


}

