import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  NgZone,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import * as esri from "angular-esri-map";
import { OpenStreetMapProvider } from "leaflet-geosearch";
import "../../../../../src/app/assets/leaflet-esri.js";
import { leaflet } from "esri-leaflet-geocoder";
declare let L: any;
declare let $: any;

@Component({
  selector: "app-mapa",
  templateUrl: "./mapa.component.html",
  styleUrls: ["./mapa.component.css"],
})
export class MapaComponent implements OnInit {
  constructor() { }
  ngOnInit() {
    let marker: any;
    var supermastickIcon = L.icon({
      iconUrl: "../../assets/market.png",

      iconSize: [38, 65],
      iconAnchor: [30, 94],
      popupAnchor: [-3, -76],
    });
    const lat = 4.655014;
    const lng = -74.093905;
    var masillas = L.layerGroup(),
      pinturas = L.layerGroup();

    L.marker([4.575264, -74.122671], { icon: supermastickIcon })
      .bindPopup(
        "<h5>ESPINOZA MENDEZ Y CIA LTD</h5>" +
        "Av. Caracas N. 42 A 28 Sur<br />" +
        "Tel: 7676965<br />" +
        "www.depositosocialespinosa.com"
      )
      .addTo(masillas)

    L.marker([4.666346, -74.147872], { icon: supermastickIcon })
      .bindPopup(
        "<h5>SAMIR</h5>" +
        "Diagonal 16 #96H-89<br />" +
        "Tel: 316 2802773<br />" +
        "www.ferreteriasamir.com"
      )
      .addTo(masillas)


    L.marker([4.63323255, -74.12132263], { icon: supermastickIcon })
      .bindPopup(
        "<h5>LOGISTICA FERRETERA S A S</h5>Calle 12 N. 14-42 <br />Tel: 3417725<br>www.logiser.com.co"
      )
      .addTo(masillas)
      .addTo(pinturas);

    L.marker([4.712717, -74.057711], { icon: supermastickIcon })
      .bindPopup(
        "<h5>MULTIOBRAS SISTEMA DRYWALL S.A.S.</h5>" +
        "Calle 128 N. 50-42<br />" +
        "Tel: 6330101<br />" +
        "www.multiobras.com.co"
      )
      .addTo(masillas);

    L.marker([4.635433, -74.169764], { icon: supermastickIcon })
      .bindPopup(
        "<h5>DISTRIBUCIONES PEGALMAX SAS</h5>" +
        "Calle 42  Sur N. 87- H 22<br />" +
        "Tel: 3053628<br />" +
        "www.pegalmax.com"
      )
      .addTo(masillas);

    L.marker([4.670571, -74.065829], { icon: supermastickIcon })
      .bindPopup(
        "<h5>PROARCA COLOMBIA S A S</h5>" +
        "Calle 79 N. 28-25<br />" +
        "Tel: 2505040<br />" +
        "www.proarca.com"
      )
      .addTo(masillas);

    L.marker([4.686469, -74.10502], { icon: supermastickIcon })
      .bindPopup(
        "<h5>DISTRIBUIDORA DE MATERIALES PARA CONSTRUCCION JOCAMAR</h5>" +
        "Av. Calle 67 Sur N. 78 D 36<br />" +
        "Tel: 7800052<br />" +
        "www.distribucionesjocamar.com"
      )
      .addTo(masillas)


    L.marker([4.592212, -74.084163], { icon: supermastickIcon })
      .bindPopup(
        "<h5>INVERSIONES SAN BERNARDO S.A.S.</h5>" +
        "Carrera 11 N. 2-45<br />" +
        "Tel: 2335065<br />" +
        "www.sanbernardo.com"
      )
      .addTo(masillas);

    L.marker([4.603031, -74.080866], { icon: supermastickIcon })
      .bindPopup(
        "<h5>FERRETERIA SERGUS LIMITADA</h5>" +
        "Calle 1214-42<br />" +
        "Tel: 3417725<br />" +
        "www.ferreteriasergus.com"
      )
      .addTo(masillas);

    L.marker([4.60394, -74.132169], { icon: supermastickIcon })
      .bindPopup(
        "<h5>EL UNIVERSO DEL YESO S.A.S</h5>" +
        "Av. 68 Sur N. 37-90 Sur <br />" +
        "Tel: 7283098<br />" +
        "www.universodelyeso.com"
      )
      .addTo(masillas);

    L.marker([4.603727, -74.08092], { icon: supermastickIcon })
      .bindPopup(
        "<h5>CENTRO FERRETERO MAFER S.A.S</h5>" +
        "Carrera 15 N. 12-65<br />" +
        "Tel: 2831215<br />" +
        "www.centroferreteromafer.com"
      )
      .addTo(masillas);

    L.marker([4.595243, -74.13758], { icon: supermastickIcon })
      .bindPopup(
        "<h5>RODRIGUEZ CAICEDO CRISTOBAL</h5>" +
        "Av. Calle 45 A Sur N. 52 C 76<br />" +
        "Tel: 2387038<br />" +
        "www.pinturasimperio.co"
      )
      .addTo(masillas);
    L.marker([4.614012, -74.084995], { icon: supermastickIcon })
      .bindPopup(
        "<h5>ABACOL</h5>" +
        "CR 25 #18-08 PALOQUEMAO<br />" +
        "Tel: 4071111-3202751705<br />"
      )
      .addTo(masillas);

    L.marker([4.863357, -74.051262], { icon: supermastickIcon })
      .bindPopup(
        "<h5>R & A GROUP S.A.S</h5>" +
        "Calle 78 N. 24-65<br />" +
        "Tel: 3004225<br />" +
        "www.drywallrya.com"
      )
      .addTo(masillas)


    L.marker([4.668358, -74.064524], { icon: supermastickIcon })
      .bindPopup(
        "<h5>R & A GROUP S.A.S</h5>" +
        "Av. Pradilla N. 3-57, Chia<br />" +
        "Tel: 8612489<br />" +
        "www.drywallrya.com"
      )
      .addTo(masillas)


    L.marker([4.60297, -74.08074], { icon: supermastickIcon })
      .bindPopup(
        "<h5>MSIERRA</h5>" +
        "Calle 12 N. 14-24<br />" +
        "Tel: 3414141<br />" +
        "www.msierraferreteros.com"
      )
      .addTo(masillas);

    L.marker([4.657636, -74.067582], { icon: supermastickIcon })
      .bindPopup(
        "<h5>MARPED GROUP S A  Suc. Siete de Agosto</h5>" +
        "Calle 68 No 20-22<br />" +
        "Tel: 7444450<br />" +
        "www.marped.com"
      )
      .addTo(masillas)
      .addTo(pinturas);

    L.marker([4.617281, -74.088965], { icon: supermastickIcon })
      .bindPopup(
        "<h5>MARPED GROUP S A  Sede paloquemao</h5>" +
        "Cra 30 No 18- 00<br />" +
        "Tel: 7444450<br />" +
        "www.marped.com"
      )
      .addTo(masillas)
      .addTo(pinturas);

    L.marker([4.706801, -74.054371], { icon: supermastickIcon })
      .bindPopup(
        "<h5>GAVILAN GOMEZ JOSE DAVID</h5>" +
        "CL 127 F No 93 97 <br />" +
        "Tel: 6812589<br />"
      )
      .addTo(masillas);

    L.marker([4.640746, -74.158022], { icon: supermastickIcon })
      .bindPopup(
        "<h5>GARZON ROJAS VILMA</h5>" +
        "Cra 86 f No 51 B - 40 Sur<br />" +
        "Tel: 7231772<br />"
      )
      .addTo(masillas)
      .addTo(pinturas);

    L.marker([4.615396, -74.089772], { icon: supermastickIcon })
      .bindPopup(
        "<h5>ADIELA DE LOMBANA S.A.  Sede Paloquemao</h5>" +
        "CALLE 17 No.28A-45 <br />" +
        "www.adieladelombana.com.co"
      )
      .addTo(masillas)
      .addTo(pinturas);

    L.marker([4.601938, -74.134392], { icon: supermastickIcon })
      .bindPopup(
        "<h5>ADIELA DE LOMBANA S.A. Sede La Alquería</h5>" +
        "CRA 68 No.37B-05  sur <br />" +
        "www.adieladelombana.com.co"
      )
      .addTo(masillas)
      .addTo(pinturas);

    L.marker([4.725576, -74.051546], { icon: supermastickIcon })
      .bindPopup(
        "<h5>ADIELA DE LOMBANA S.A. Sede Norte</h5>" +
        "CALLE 143 No.45-57 <br />" +
        "Tel: 742 1010<br />" +
        "www.adieladelombana.com.co"
      )
      .addTo(masillas)
      .addTo(pinturas);

    L.marker([4.712091, -74.211284], { icon: supermastickIcon })
      .bindPopup(
        "<h5>ADIELA DE LOMBANA S.A. Sede Funza</h5>" +
        "Carrera 9 # 12 - 51 <br />" +
        "Tel: 3905777<br />" +
        "www.adieladelombana.com.co"
      )
      .addTo(masillas)
      .addTo(pinturas);

    L.marker([4.613418, -74.104138], { icon: supermastickIcon })
      .bindPopup(
        "<h5>FERRELUGUE SAS Principal</h5>" +
        "Calle 6 No 37 -78<br />" +
        "www.ferrelugue.com"
      )
      .addTo(masillas)
      .addTo(pinturas);

    L.marker([4.658202, -74.068692], { icon: supermastickIcon })
      .bindPopup(
        "<h5>FERRELUGUE SAS Sede 7 de Agosto</h5>" +
        "Calle 68 No 22 - 25<br />" +
        "Tel: 2125032<br />" +
        "www.ferrelugue.com"
      )
      .addTo(masillas)
      .addTo(pinturas);

    L.marker([4.745666, -74.040128], { icon: supermastickIcon })
      .bindPopup(
        "<h5>FERRELUGUE SAS Toberin</h5>" +
        "Calle 166 No 17 - 29<br />" +
        "Tel: 6051188<br />" +
        "www.ferrelugue.com"
      )
      .addTo(masillas)
      .addTo(pinturas);

    L.marker([4.602215, -74.090962], { icon: supermastickIcon })
      .bindPopup(
        "<h5>FERRELUGUE SAS Sede Cra 19</h5>" +
        "CALLE 6 # 19 A - 23<br />" +
        "www.ferrelugue.com"
      )
      .addTo(masillas)
      .addTo(pinturas);

    L.marker([4.65332, -74.142863], { icon: supermastickIcon })
      .bindPopup(
        "<h5>DEPOSITO Y FERRETERIA DELGADO DE SUBA</h5>" +
        " AVD CIUDAD DE CALI No 129 D - 16<br />" +
        "www.depositoyferreteriadelgado.com"
      )
      .addTo(masillas)
      .addTo(pinturas);

    L.marker([4.618997, -74.176096], { icon: supermastickIcon })
      .bindPopup(
        "<h5>DISTRI MATERIALES LA UNION S.A.S.</h5>" +
        "Calle 54 sur 81C - 23<br />" +
        "Tel: 450 20 60<br />"
      )
      .addTo(masillas)
      .addTo(pinturas)


    L.marker([4.716205, -74.059334], { icon: supermastickIcon })
      .bindPopup(
        "<h5>F129 FERRETERIA 129 SAS</h5>" +
        "Calle 129 No 53-04<br />" +
        "Tel: 2583040<br />" +
        "www.ferreteria129.com.co"
      )
      .addTo(masillas)
      .addTo(pinturas)


    L.marker([4.669472, -74.06468], { icon: supermastickIcon })
      .bindPopup(
        "<h5>MONTOYA GOMEZ LUIS OMAR SEDE Santa Sofia</h5>" +
        "Cr. 27 # 78 - 62<br />" +
        "Tel: 3110937<br />"
      )
      .addTo(masillas)
      .addTo(pinturas)


    L.marker([4.602746, -74.080388], { icon: supermastickIcon })
      .bindPopup(
        "<h5>MONTOYA GOMEZ LUIS OMAR SEDE Centro</h5>" +
        "Avenica Caracas No 12 - 03<br />" +
        "Tel: 3427650<br />"
      )
      .addTo(masillas)
      .addTo(pinturas)


    L.marker([4.62654407236275, -74.17793807194215], { icon: supermastickIcon })
      .bindPopup(
        "<h5>COMERCIALIZADORA PRODECON</h5>" +
        "Cra 86 f No 51 B - 40 Sur<br />" +
        "Tel: 7231772<br />"
      )
      .addTo(masillas)
      .addTo(pinturas);

    L.marker([4.673266, -74.146284], { icon: supermastickIcon })
      .bindPopup(
        "<h5>FERREPINTURAS JP SAS</h5>" +
        "CALLE 17 No 100 - 48<br />" +
        "Tel: 7551786<br />"
      )
      .addTo(masillas)
      .addTo(pinturas);

    L.marker([4.604161, -74.082405], { icon: supermastickIcon })
      .bindPopup(
        "<h5>CONSTRUCDRYWALL, SAS</h5>" +
        "CR 16 No  12 08<br />" +
        "Tel: 3426402<br />"
      )
      .addTo(masillas)
      .addTo(pinturas)


    L.marker([4.74406, -74.045862], { icon: supermastickIcon })
      .bindPopup(
        "<h5>PANELROCK COLOMBIA SA</h5>" +
        "Calle 163 A No. 21 - 68<br />" +
        "Tel: 7431771<br />" +
        "www.panelrockcolombia.com"
      )
      .addTo(masillas, pinturas)


    L.marker([4.855437, -74.064574], { icon: supermastickIcon })
      .bindPopup(
        "<h5>HIERROS Y CEMENTOS EL LAGUITO SAS</h5>" +
        "Cll 129 C Nª 96 A - 02<br />" +
        "Tel: 6804052<br />"
      )
      .addTo(masillas)
      .addTo(pinturas);

    L.marker([4.687987, -74.096129], { icon: supermastickIcon })
      .bindPopup(
        "<h5>CREACIONES MUNDO YESO</h5>" +
        "Cra 72 Nª 72A-09<br />" +
        "Tel: 4344202<br />"
      )
      .addTo(masillas);

    L.marker([4.729545, -74.028451], { icon: supermastickIcon })
      .bindPopup(
        "<h5>DEPOSITO LA CONFIANZA LTDA</h5>" +
        "Calle 153 No 7 G - 04<br />" +
        "Tel: 6149487<br />"
      )
      .addTo(masillas);

    L.marker([4.571161, -74.124469], { icon: supermastickIcon })
      .bindPopup(
        "<h5>VILLALOBOS FORERO LUIS ENRIQUE</h5>" +
        "Avd Caracas Nª 45 c - 01 Sur<br />" +
        "Tel: 7604069<br />"
      )
      .addTo(masillas);

    L.marker([4.656609, -74.066325], { icon: supermastickIcon })
      .bindPopup(
        "<h5>DISTRIBUIDORA NACIONAL DE YESOS SAS</h5>" +
        "Cl. 68 # 18 - 11<br />" +
        "Tel: 2552575<br />"
      )
      .addTo(masillas)
      .addTo(pinturas)


    L.marker([4.716129, -74.059663], { icon: supermastickIcon })
      .bindPopup(
        "<h5>ULTRAPINTURAS SAS</h5>" +
        "Calle 129 No 53 - 29<br />" +
        "Tel: 6808003<br />" +
        "www.ultrapinturas.com"
      )
      .addTo(masillas);

    L.marker([4.910333, -74.028122], { icon: supermastickIcon })
      .bindPopup(
        "<h5>DISTRIBUIDORA NACIONAL DE YESOS SAS  Sede Cajica</h5>" +
        "Carrera 5a # 4 sur -113 (Vía Principal)<br />" +
        "Tel: 2552575<br />"
      )
      .addTo(masillas)
      .addTo(pinturas)


    L.marker([4.620637, -74.166615], { icon: supermastickIcon })
      .bindPopup(
        "<h5>CENTRO DE ACABADOS Y REVESTIMIENTOS EXTRACOLOR SAS</h5>" +
        "cra 80 Nª 43-64/74 sur<br />" +
        "Tel: 2642119/4812766<br />"
      )
      .addTo(masillas)


    L.marker([4.657033, -74.066739], { icon: supermastickIcon })
      .bindPopup(
        "<h5>TECNICAS EN YESO T & Y SAS</h5>" +
        "Calle 68 N° 19 24<br />" +
        "Tel: 6068464<br />" +
        "www.tecnicasenyeso.com.co"
      )
      .addTo(masillas)
      .addTo(pinturas)


    L.marker([4.740655, -74.095414], { icon: supermastickIcon })
      .bindPopup(
        "<h5>ULTRAPINTURAS SAS</h5>" +
        "CL 139 No 102 - 78<br />" +
        "Tel: 6808003<br />" +
        "www.ultrapinturas.com"
      )
      .addTo(masillas);

    L.marker([4.745725, -74.127384], { icon: supermastickIcon })
      .bindPopup(
        "<h5>SOSA DISTRIBUCIONES S A S Sede Suba</h5>" +
        " CALLE 139   No 103 C - 01 <br />" +
        "Tel: 6822409<br />"
      )
      .addTo(masillas);

    L.marker([4.694591, -74.104167], { icon: supermastickIcon })
      .bindPopup(
        "<h5>SOSA DISTRIBUCIONES S A S</h5>" +
        "AVD CALLE 72 No 83-21<br />" +
        "Tel: 6822409<br />"
      )
      .addTo(masillas);

    L.marker([4.743791, -74.098745], { icon: supermastickIcon })
      .bindPopup(
        "<h5>OYOLA PERILLA AURA MARIA</h5>" +
        "Cra 107 Nª 139-59<br />" +
        "Tel: 6936465<br />"
      )
      .addTo(masillas)
      .addTo(pinturas)


    L.marker([4.560263, -74.096583], { icon: supermastickIcon })
      .bindPopup(
        "<h5>AVILA PULIDO EXCELIANO</h5>" +
        "CL 35 C SUR No 26 C 36 <br />" +
        "Tel: 7208720<br />"
      )
      .addTo(masillas)
      .addTo(pinturas);

    L.marker([4.726476, -74.051833], { icon: supermastickIcon })
      .bindPopup(
        "<h5>ACABADOS Y YESOS SAS sede Norte</h5>" +
        "Cl. 144 # 46 - 21 <br />" +
        "Tel: 6251205<br />" +
        "www.acabadosyyesos.com"
      )
      .addTo(masillas)
      .addTo(pinturas);

    L.marker([4.752838, -74.134714], { icon: supermastickIcon })
      .bindPopup(
        "<h5>ACABADOS Y YESOS SAS sede Siberia</h5>" +
        "SIBERIA COTA CENTRO EMPRESARIAL  CIEM OIKOS BODEGA  134 <br />" +
        "Tel: 8776492<br />" +
        "www.acabadosyyesos.com"
      )
      .addTo(masillas)
      .addTo(pinturas);

    L.marker([4.620684, -74.166469], { icon: supermastickIcon })
      .bindPopup(
        "<h5>CHAVEZ CARLOS ALFONSO</h5>" +
        "Cra 80 No 43 - 50 sur <br />" +
        "Tel: 4509469<br />" +
        "www.durocolor.com"
      )
      .addTo(masillas)
      .addTo(pinturas)


    L.marker([4.70405, -74.103636], { icon: supermastickIcon })
      .bindPopup(
        "<h5>DISEÑOS Y ACABADOS 1A SAS sede Calle 80</h5>" +
        "AV CL 80 No  89 75 <br />" +
        "Tel: 4904932<br />"
      )
      .addTo(masillas)
      .addTo(pinturas)


    L.marker([4.758308, -74.030711], { icon: supermastickIcon })
      .bindPopup(
        "<h5>DISENSA DEPOSITO Y FERRETERIA</h5>" +
        "CARRERA 8D #181-85<br />" +
        "Tel: 3115136502<br />" +
        "www.disensa.com.co"
      )
      .addTo(masillas);

    L.marker([4.685861, -74.15592], { icon: supermastickIcon })
      .bindPopup(
        "<h5>DISENSA DEPOSITO Y FERRETERIA</h5>" +
        "CALLE 17D #118B-15 FONTIBON<br />" +
        "Tel: 4180406-3112299343<br />" +
        "www.disensa.com.co"
      )
      .addTo(masillas);

    L.marker([4.678212, -74.150145], { icon: supermastickIcon })
      .bindPopup(
        "<h5>DISENSA DEPOSITO Y FERRETERIA</h5>" +
        "CALLE 17 #108-18<br />" +
        "Tel: 4157486, 4156042<br />" +
        "www.disensa.com.co"
      )
      .addTo(masillas);

    L.marker([4.685726, -74.06421], { icon: supermastickIcon })
      .bindPopup(
        "<h5>DISENSA DEPOSITO Y FERRETERIA</h5>" +
        "CARRERA 57 #97-47<br />" +
        "Tel: 3214451713<br />" +
        "www.disensa.com.co"
      )
      .addTo(masillas);

    L.marker([4.589345, -74.076821], { icon: supermastickIcon })
      .bindPopup(
        "<h5>DISENSA DEPOSITO Y FERRETERIA</h5>" +
        "CARRERA 4 #3-26 LOCAL 6<br />" +
        "Tel: 3204384993<br />" +
        "www.disensa.com.co"
      )
      .addTo(masillas);

    L.marker([4.669691, -74.073414], { icon: supermastickIcon })
      .bindPopup(
        "<h5>DISENSA DEPOSITO Y FERRETERIA</h5>" +
        "CARRERA 51 #73-80<br />" +
        "Tel: 3103419054, 3213721696<br />" +
        "www.disensa.com.co"
      )
      .addTo(masillas);

    L.marker([4.655821, -74.064445], { icon: supermastickIcon })
      .bindPopup(
        "<h5>DISENSA DEPOSITO Y FERRETERIA</h5>" +
        "CALLE 68 #14A-82<br />" +
        "Tel: 3167411480<br />" +
        "www.disensa.com.co"
      )
      .addTo(masillas);

    L.marker([4.629432, -74.109244], { icon: supermastickIcon })
      .bindPopup(
        "<h5>DISENSA DEPOSITO Y FERRETERIA</h5>" +
        "CALLE 13 #54-34<br />" +
        "Tel: 3102716097, 3124348493<br />" +
        "www.disensa.com.co"
      )
      .addTo(masillas);

    L.marker([4.669961, -74.10854], { icon: supermastickIcon })
      .bindPopup(
        "<h5>DISENSA DEPOSITO Y FERRETERIA</h5>" +
        "AVENIDA BOYACA #35-43 SUR<br />" +
        "Tel: 2994350<br />" +
        "www.disensa.com.co"
      )
      .addTo(masillas);

    L.marker([4.603451, -74.081229], { icon: supermastickIcon })
      .bindPopup(
        "<h5>DISENSA DEPOSITO Y FERRETERIA</h5>" +
        "CALLE 12 #15-08<br />" +
        "Tel: 3123503454<br />" +
        "www.disensa.com.co"
      )
      .addTo(masillas);

    L.marker([4.558152, -74.098366], { icon: supermastickIcon })
      .bindPopup(
        "<h5>DISENSA DEPOSITO Y FERRETERIA</h5>" +
        "CALLE 37A #3-82 SUR<br />" +
        "Tel: 3214520354<br />" +
        "www.disensa.com.co"
      )
      .addTo(masillas);

    L.marker([4.549818, -74.086746], { icon: supermastickIcon })
      .bindPopup(
        "<h5>DISENSA DEPOSITO Y FERRETERIA</h5>" +
        "CARRERA 8D ESTE #38-71 SUR<br />" +
        "Tel: 3193312519<br />" +
        "www.disensa.com.co"
      )
      .addTo(masillas);

    L.marker([4.512366, -74.114697], { icon: supermastickIcon })
      .bindPopup(
        "<h5>DISENSA DEPOSITO Y FERRETERIA</h5>" +
        "CALLE 76A SUR #12-05<br />" +
        "Tel: 3838505, 2497434, 3115617466, 3132850002<br />" +
        "www.disensa.com.co"
      )
      .addTo(masillas);

    L.marker([4.496837, -74.103542], { icon: supermastickIcon })
      .bindPopup(
        "<h5>DISENSA DEPOSITO Y FERRETERIA</h5>" +
        "CARRERA 6 ESTE #91-09 SUR<br />" +
        "Tel: 3133769496<br />" +
        "www.disensa.com.co"
      )
      .addTo(masillas);

    L.marker([4.56894, -74.125781], { icon: supermastickIcon })
      .bindPopup(
        "<h5>AVILA NUBIA ESPERANZA</h5>" +
        "Diagonal 48 sur No 18 A - 14 <br />" +
        "Tel: 7145310<br />" +
        "www.disensa.com.co"
      )
      .addTo(masillas);

    L.marker([4.930221, -74.172742], { icon: supermastickIcon })
      .bindPopup(
        "<h5>DISENSA</h5>" +
        "CALLE 5 #3-51<br />" +
        "3134944348, 8245876<br />" +
        "www.disensa.com.co"
      )
      .addTo(masillas);

    L.marker([4.521007, -74.592079], { icon: supermastickIcon })
      .bindPopup(
        "<h5>DISENSA</h5>" +
        "AV. COLOMBIA CARRERA 5 #13-27<br />" +
        "3502526026, 8388293<br />" +
        "www.disensa.com.co"
      )
      .addTo(masillas);

    L.marker([4.734465, -74.260529], { icon: supermastickIcon })
      .bindPopup(
        "<h5>DISENSA</h5>" +
        "CARRERA 6 #9-84 LA MAGNOLIA<br />" +
        "3112120409, 8207876<br />" +
        "www.disensa.com.co"
      )
      .addTo(masillas);

    L.marker([5.145869, -73.685343], { icon: supermastickIcon })
      .bindPopup(
        "<h5>DISENSA</h5>" +
        "CALLE 6 #5-56<br />" +
        "3153286696, 8562323<br />" +
        "www.disensa.com.co"
      )
      .addTo(masillas);

    L.marker([5.339344, -72.392191], { icon: supermastickIcon })
      .bindPopup(
        "<h5>DISENSA</h5>" +
        "CARRERA 23 # 21-37<br />" +
        "3112296748, 6341357<br />" +
        "www.disensa.com.co"
      )
      .addTo(masillas);

    L.marker([4.325445, -74.405615], { icon: supermastickIcon })
      .bindPopup(
        "<h5>DISENSA</h5>" +
        "CALLE 23 #62A-07<br />" +
        "3182882538, 8862332<br />" +
        "www.disensa.com.co"
      )
      .addTo(masillas);

    L.marker([3.985395, -73.767339], { icon: supermastickIcon })
      .bindPopup(
        "<h5>DISENSA</h5>" +
        "CARRERA 24 #12-24 BARRIO EL DORADO<br />" +
        "3213504415, 3223280578, 6563163<br />" +
        "www.disensa.com.co"
      )
      .addTo(masillas);

    L.marker([3.878558, -73.772568], { icon: supermastickIcon })
      .bindPopup(
        "<h5>DISENSA</h5>" +
        "CARRERA 3A #13-30 BARRIO FUNDADORES<br />" +
        "3115967126<br />" +
        "www.disensa.com.co"
      )
      .addTo(masillas);

    L.marker([4.909273, -74.028247], { icon: supermastickIcon })
      .bindPopup(
        "<h5>DISENSA</h5>" +
        "CARRERA 5 #4-101<br />" +
        "31243299961, 8648670<br />" +
        "www.disensa.com.co"
      )
      .addTo(masillas);
    L.marker([4.713956, -74.209989], { icon: supermastickIcon })
      .bindPopup(
        "<h5>DISENSA</h5>" +
        "AVENIDA 10 #14-77<br />" +
        "3143487679, 8257814<br />" +
        "www.disensa.com.co"
      )
      .addTo(masillas);

    L.marker([4.149402, -74.718894], { icon: supermastickIcon })
      .bindPopup(
        "<h5>DISENSA</h5>" +
        "CALLE 2 #8-19 BARRIO ARENITAS<br />" +
        "3114402729<br />" +
        "www.disensa.com.co"
      )
      .addTo(masillas);

    L.marker([4.4934, -74.258534], { icon: supermastickIcon })
      .bindPopup(
        "<h5>CONSTRUCTOR LTDA</h5>" +
        "CARRERA 7 #12-25<br />" +
        "3212068369<br />" +
        "www.disensa.com.co"
      )
      .addTo(masillas);

    L.marker([4.556767, -74.529272], { icon: supermastickIcon })
      .bindPopup(
        "<h5>DISENSA</h5>" +
        "CARRERA 5 #12-83 BARRIO LIBERIA<br />" +
        "8993161, 3002700167<br />" +
        "www.disensa.com.co"
      )
      .addTo(masillas);

    L.marker([4.345189, -74.376911], { icon: supermastickIcon })
      .bindPopup(
        "<h5>DISENSA</h5>" +
        "CARRERA 27 #4-51 VIA PANAMERICANA<br />" +
        "3203049377, 3212549802, 8674270<br />" +
        "www.disensa.com.co"
      )
      .addTo(masillas);

    L.marker([5.024628, -73.986001], { icon: supermastickIcon })
      .bindPopup(
        "<h5>DISENSA</h5>" +
        "CALLE 8 #32-106 LA PAZ<br />" +
        "3102769401, 8513735<br />" +
        "www.disensa.com.co"
      )
      .addTo(masillas);

    L.marker([4.4386, -75.192529], { icon: supermastickIcon })
      .bindPopup(
        "<h5>DISENSA</h5>" +
        "CARRERA 5<br />" +
        "3102863528<br />" +
        "www.disensa.com.co"
      )
      .addTo(masillas);

    L.marker([4.808497, -74.102012], { icon: supermastickIcon })
      .bindPopup(
        "<h5>DISENSA</h5>" +
        "CALLE 11 #4-17<br />" +
        "3102966586, 8640486<br />" +
        "www.disensa.com.co"
      )
      .addTo(masillas);

    L.marker([4.520225, -74.350714], { icon: supermastickIcon })
      .bindPopup(
        "<h5>DISENSA</h5>" + "CALLE 12 #14-25<br />" + "www.disensa.com.co"
      )
      .addTo(masillas);

    L.marker([4.638992, -74.447747], { icon: supermastickIcon })
      .bindPopup(
        "<h5>DISENSA</h5>" +
        "CALLE 4A #26-197 BARRIO LA CEIBA<br />" +
        "3134566214, 3114411836" +
        "www.disensa.com.co"
      )
      .addTo(masillas);

    L.marker([3.5377927890408403, -73.70538286013769], { icon: supermastickIcon })
      .bindPopup(
        "<h5>DISENSA FERRETERIA EL FARO</h5>" +
        "CALLE 12 #14-25<br />" +
        "328 4583700" +
        "E-mail: info-colombia@disensa.com" +
        "www.disensa.com.co"
      )
      .addTo(masillas);

    L.marker([6.152803261484444, -71.76858533130367], { icon: supermastickIcon })
      .bindPopup(
        "<h5>DISENSA</h5>" +
        "Calle 4 #12-39<br />" +
        "3182672662" +
        "E-mail: info-colombia@disensa.com" +
        "www.disensa.com.co"
      )
      .addTo(masillas);

    L.marker([3.6227937751815595, -75.09574360246813], { icon: supermastickIcon })
      .bindPopup(
        "<h5>DISENSA FERRETERIA MARCA</h5>" +
        "CARRERA 55-45<br />" +
        "330 4583700" +
        "E-mail: info-colombia@disensa.com" +
        "www.disensa.com.co"
      )
      .addTo(masillas);

    L.marker([4.448062, -75.178928], { icon: supermastickIcon })
      .bindPopup(
        "<h5>DISENSA</h5>" +
        "CARRERA 21 #67 BARRIO LOS ANGELES<br />" +
        "3114839678, 2798236" +
        "www.disensa.com.co"
      )
      .addTo(masillas);

    L.marker([4.201455, -74.644745], { icon: supermastickIcon })
      .bindPopup(
        "<h5>DISENSA</h5>" +
        "CALLE 7 #49-10 BARRIO RESACAS<br />" +
        "3107591438, 3123069985, 3132611398" +
        "www.disensa.com.co"
      )
      .addTo(masillas);

    L.marker([4.405564, -73.946266], { icon: supermastickIcon })
      .bindPopup(
        "<h5>DISENSA</h5>" +
        "CALLE 2A #4-15<br />" +
        "3108845028" +
        "www.disensa.com.co"
      )
      .addTo(masillas);

    L.marker([4.622529, -74.166788], { icon: supermastickIcon })
      .bindPopup(
        "<h5>DISEÑOS Y ACABADOS 1A SAS sede Avd Villavicencio</h5>" +
        "AV VILLAVICENCIO No 80 G - 03 <br />" +
        "Tel: 4904932<br />"
      )
      .addTo(masillas)
      .addTo(pinturas)

    L.marker([4.139313, -73.634496], { icon: supermastickIcon })
      .bindPopup(
        "<h5>CONSTRUCTOR  ZONA LIBRE</h5>" +
        "CLL 26 #36-21 BARRIO 7 DE AGOSTO <br />" +
        "Tel: 6779844<br />"
      )
      .addTo(masillas)
      .addTo(pinturas)

    L.marker([4.133996, -73.618425], { icon: supermastickIcon })
      .bindPopup(
        "<h5>COVAL San Ignacio</h5>" +
        "CARRERA 16 #13-40 SAN IGNACIO   <br />" +
        "Tel: 6784462<br />"
      )
      .addTo(masillas)
      .addTo(pinturas)


    L.marker([4.134017, -73.618415], { icon: supermastickIcon })
      .bindPopup(
        "<h5>COVAL </h5>" +
        "CARRERA 16 #13-40 SAN IGNACIO  <br />" +
        "Tel: 6784462<br />"
      )
      .addTo(masillas)
      .addTo(pinturas)


    L.marker([4.139406, -73.637454], { icon: supermastickIcon })
      .bindPopup(
        "<h5>COVAL </h5>" +
        "Calle 26b #38-56 7 DE AGOSTO<br />" +
        "Tel: 6784462<br />"
      )
      .addTo(masillas)
      .addTo(pinturas)


    L.marker([4.86332, -74.051938], { icon: supermastickIcon })
      .bindPopup(
        "<h5>COVAL </h5>" +
        "Avenida Pradilla # 4 – 24<br />" +
        "Tel: 875-9100<br />"
      )
      .addTo(masillas)
      .addTo(pinturas)


    L.marker([4.86332, -74.051938], { icon: supermastickIcon })
      .bindPopup(
        "<h5>COVAL </h5>" +
        "Autopista Medellín Km 2.5, Parque Industrial Guadalajara, Bod 3.<br />" +
        "Tel: 8759100<br />"
      )
      .addTo(masillas)
      .addTo(pinturas)


    L.marker([4.679963, -74.094254], { icon: supermastickIcon })
      .bindPopup(
        "<h5>THERMOLINE SAS</h5>" +
        "Cra 70 No 68 A - 36 <br />" +
        "Tel: 6604046<br />" +
        "www.thermolinesas.com"
      )
      .addTo(masillas)
      .addTo(pinturas)


    L.marker([4.742655, -74.100666], { icon: supermastickIcon })
      .bindPopup(
        "<h5>CONSTRUCENTER SOLO TEJAS SAS sede Principal</h5>" +
        "Cl. 139 # 108 a 31  <br />" +
        "Tel: 6882500<br />" +
        "www.solotejas.com"
      )
      .addTo(masillas);

    L.marker([4.716194, -74.059022], { icon: supermastickIcon })
      .bindPopup(
        "<h5>CONSTRUCENTER SOLO TEJAS SAS sede Prado</h5>" +
        "Calle 129 No 52 - 66 <br />" +
        "Tel: 6055000<br />" +
        "www.solotejas.com"
      )
      .addTo(masillas);

    L.marker([4.744286, -74.097929], { icon: supermastickIcon })
      .bindPopup(
        "<h5>CONSTRUCENTER SOLO TEJAS SAS sede Avd Cali</h5>" +
        "Cra 104  No 140 A - 69 <br />" +
        "Tel: 6051212<br />" +
        "www.solotejas.com"
      )
      .addTo(masillas);

    L.marker([4.742912, -74.098101], { icon: supermastickIcon })
      .bindPopup(
        "<h5>CONSTRUCENTER SOLO TEJAS SAS sede Solo Drywall</h5>" +
        "Cra 104  No 140 - 29 <br />" +
        "Tel: 6055000<br />" +
        "www.solotejas.com"
      )
      .addTo(masillas);

    L.marker([4.673763, -74.146711], { icon: supermastickIcon })
      .bindPopup(
        "<h5>CONSTRUCENTER SOLO TEJAS SAS sede Fontibon</h5>" +
        "Calle 17 No 102 - 16 <br />" +
        "Tel: 6055000<br />" +
        "www.solotejas.com"
      )
      .addTo(masillas);

    L.marker([4.85569, -74.064747], { icon: supermastickIcon })
      .bindPopup(
        "<h5>HIERROS Y CEMENTOS DE CHIA SAS</h5>" +
        "CARRERA 12 N.4 50 <br />" +
        "Tel: 8852537<br />"
      )
      .addTo(masillas)
      .addTo(pinturas);

    L.marker([4.572939, -74.125897], { icon: supermastickIcon })
      .bindPopup(
        "<h5>CEMATCOL- CEMENTOS Y MATERIALES DE COLOMBIA</h5>" +
        " CALLE 46 SUR No 20 – 71 SUR  SANTA LUCIA <br />" +
        "Tel: 7606204<br />"
      )
      .addTo(masillas);

    L.marker([4.641511, -74.114418], { icon: supermastickIcon })
      .bindPopup(
        "<h5>DINALYE - DISTRIBUIDORA NACIONAL DE YESOS SAS</h5>" +
        "Cl. 68 # 18 - 11 <br />" +
        "Tel: 2552575<br />"
      )
      .addTo(masillas);

    L.marker([5.340266, -72.396594], { icon: supermastickIcon })
      .bindPopup(
        "<h5>ESTUCOS Y VENECIANOS CASANARES SAS</h5>" +
        "Cll 40 #6a-38 Brisas del llano <br />" +
        "Tel: (8) 6347750 /6333438<br />"
      )
      .addTo(masillas)


    L.marker([4.496877, -74.103258], { icon: supermastickIcon })
      .bindPopup(
        "<h5>SAAVEDRA SEDANO MARIA DEL CARMEN</h5>" +
        "CL 91 SUR No 6A- 12 ESTE <br />" +
        "Tel: 7630430<br />"
      )
      .addTo(masillas);

    L.marker([4.63437, -74.118516], { icon: supermastickIcon })
      .bindPopup(
        "<h5>DISTRI HIERROS DAPISA SAS</h5>" +
        "Cra 66 A No 11 - 71<br />" +
        "Tel: 3123390736<br />" +
        "www.dapisadistribuciones.com"
      )
      .addTo(masillas);

    L.marker([4.592148, -74.194134], { icon: supermastickIcon })
      .bindPopup(
        "<h5>DISCERCOL GROUP</h5>" +
        "CRA 7 No 47 - 71 Soacha - Leon XIII<br />" +
        "Tel: 7769564<br />" +
        "www.dapisadistribuciones.com"
      )
      .addTo(masillas);

    L.marker([44.65652, -74.066257], { icon: supermastickIcon })
      .bindPopup(
        "<h5>DINALYE - DISTRIBUIDORA NACIONAL DE YESOS SAS  Sede Cajica</h5>" +
        "Carrera 5a # 4 sur -113 (Vía Principal)<br />" +
        "Tel: 2552575<br />" +
        "http://dinalye.co/.com"
      )
      .addTo(masillas)
      .addTo(pinturas);

    L.marker([44.65652, -74.066257], { icon: supermastickIcon })
      .bindPopup(
        "<h5>DINALYE - DISTRIBUIDORA NACIONAL DE YESOS SAS  Sede Cajica</h5>" +
        "Carrera 5a # 4 sur -113 (Vía Principal)<br />" +
        "Tel: 2552575<br />" +
        "http://dinalye.co/.com"
      )
      .addTo(masillas)
      .addTo(pinturas);

    L.marker([4.62053, -74.166568], { icon: supermastickIcon })
      .bindPopup(
        "<h5>EXTRACOLOR CENTRO DE ACABADOS Y REVESTIMIENTOS EXTRACOLOR SAS</h5>" +
        "cra 80 Nª 43-64/74 sur<br />" +
        "Tel: 2642119/4812766<br />"
      )
      .addTo(masillas);

    L.marker([4.731337, -74.024135], { icon: supermastickIcon })
      .bindPopup(
        "<h5>LA PERLA DEL COLOR SAS</h5>" +
        " Avd 7 No 155 C- 19<br />" +
        "Tel: 6711817<br />"
      )
      .addTo(masillas);

    L.marker([4.497003, -74.103026], { icon: supermastickIcon })
      .bindPopup(
        "<h5>DEPOSITO ALFONSO LOPEZ /TORRES MORENO PABLO ENRIQUE</h5>" +
        "CRA 6 ESTE No 91 - 09 SUR<br />" +
        "Tel: 7687353<br />"
      )
      .addTo(masillas);

    L.marker([4.679658, -74.094945], { icon: supermastickIcon })
      .bindPopup(
        "<h5>ACITECHOS ACABADOS Y ARQUITECTURA S.A.S.</h5>" +
        "CALLE 68 No 70 - 26 <br />" +
        "Tel: 2247510<br />" +
        "www.acitechos.com.co"
      )
      .addTo(masillas)


    L.marker([4.677295, -74.085603], { icon: supermastickIcon })
      .bindPopup(
        "<h5>SOLUCIONES DECORATIVAS PINTU COLOR E.U</h5>" +
        "Calle 72 No 67 - 34 <br />" +
        "Tel: 4822809<br />"
      )
      .addTo(masillas)

    L.marker([4.68936952491321, -74.09750674664303], { icon: supermastickIcon })
      .bindPopup(
        "<h5>SOSA DISTRIBUCIONES S A S Sede  Calle 72</h5>" +
        "AVD CALLE 72 No 83-21<br />" +
        "Tel: 6822409<br />" +
        "Email: sosadistribuciones@hotmail.com"
      )
      .addTo(masillas)

    L.marker([4.6893373883332465, -74.09758188250248], { icon: supermastickIcon })
      .bindPopup(
        "<h5>SOSA DISTRIBUCIONES S A S Sede  Suba</h5>" +
        " CALLE 139   No 103 C - 01<br />" +
        "Tel: 6822409<br />" +
        "Email: sosadistribuciones@hotmail.com"
      )
      .addTo(masillas)

    L.marker([4.663661, -74.067632], { icon: supermastickIcon })
      .bindPopup(
        "<h5>ACABADOS Y ARQUITECTURA ECA S.A.S</h5>" +
        "Cl. 72 # 25 - 10 <br />" +
        "Tel: 6309891<br />" +
        "www.ayaeca.com"
      )
      .addTo(masillas);

    // Medellin

    L.marker([6.345119, -75.525013], { icon: supermastickIcon })
      .bindPopup(
        "<h5>NORTE MATERIALES, S.A.S</h5>" +
        "DIAG 52 # 12 - 337  <br />" +
        "Tel: 4499602<br />"
      )
      .addTo(masillas)
      .addTo(pinturas)


    L.marker([6.242916, -75.569654], { icon: supermastickIcon })
      .bindPopup(
        "<h5>PINTURAS & YESOS S.A.</h5>" +
        "CARRERA 48 N° 42-73<br />" +
        "Tel: 4481802<br />" +
        "www.pinturasyyesos.com"
      )
      .addTo(masillas);

    L.marker([6.2496, -75.590948], { icon: supermastickIcon })
      .bindPopup(
        "<h5>PINTURAS & YESOS S.A.</h5>" +
        "CALLE 44 # 71 - 93<br />" +
        "Tel: 3113581485<br />" +
        "www.pinturasyyesos.com"
      )
      .addTo(masillas);

    L.marker([6.168201, -75.582724], { icon: supermastickIcon })
      .bindPopup(
        "<h5>PINTURAS & YESOS S.A.</h5>" +
        "CALLE 36 SUR # 35 - 42<br />" +
        "Tel: 3104493023<br />" +
        "www.pinturasyyesos.com"
      )
      .addTo(masillas);

    L.marker([6.337972, -75.548045], { icon: supermastickIcon })
      .bindPopup(
        "<h5>PINTURAS & YESOS S.A.</h5>" +
        "AVENIDA  42 DG 53 - 10<br />" +
        "Tel: 3207881885<br />" +
        "www.pinturasyyesos.com"
      )
      .addTo(masillas);

    L.marker([7.877841, -76.633143], { icon: supermastickIcon })
      .bindPopup(
        "<h5>PINTURAS & YESOS S.A.</h5>" +
        "CALLE 91 # 99 - 18<br />" +
        "Tel: 3113581484<br />" +
        "www.pinturasyyesos.com"
      )
      .addTo(masillas);

    L.marker([7.990135, -75.200085], { icon: supermastickIcon })
      .bindPopup(
        "<h5>PINTURAS & YESOS S.A.</h5>" +
        "CARRERA 20 LA TRONCAL FRENTE IDEM<br />" +
        "Tel: 3207881853<br />" +
        "www.pinturasyyesos.com"
      )
      .addTo(masillas);

    L.marker([6.156097, -75.368554], { icon: supermastickIcon })
      .bindPopup(
        "<h5>PINTURAS & YESOS S.A.</h5>" +
        "CALLE 55 # 43 - 56<br />" +
        "Tel: 3113671591<br />" +
        "www.pinturasyyesos.com"
      )
      .addTo(masillas);

    L.marker([6.225903, -75.572476], { icon: supermastickIcon })
      .bindPopup(
        "<h5>MARPED GROUP S A</h5>" +
        "CARRERA 43 G # 24-32<br />" +
        "Tel: 034-7444450<br />" +
        "www.marped.com"
      )
      .addTo(masillas)
      .addTo(pinturas)


    L.marker([6.336738, -75.548195], { icon: supermastickIcon })
      .bindPopup(
        "<h5>MADEBELLO/ MATERIALES BETANCOURT USCATEGUI SAS</h5>" +
        "DIAGONAL 51 43 A 63<br />" +
        "Tel: 4830507<br />"
      )
      .addTo(masillas)
      .addTo(pinturas)

    L.marker([6.336738, -75.548195], { icon: supermastickIcon })
      .bindPopup(
        "<h5>MADEIMPER - MADERAS E IMPERMEABILIZANTES BOGOTA</h5>" +
        "CR 45 A No 128 C - 33<br />" +
        "Tel: 4029131<br />"
      )
      .addTo(masillas)
      .addTo(pinturas);

    L.marker([6.163852, -75.619791], { icon: supermastickIcon })
      .bindPopup(
        "<h5>GRUPO SAN PIO SAS</h5>" +
        "DIAGONAL 47 No 31 67<br />" +
        "Tel: (4) 444-0960<br />" +
        "http://gruposanpio.com/"
      )
      .addTo(masillas);

    L.marker([6.269684, -75.437656], { icon: supermastickIcon })
      .bindPopup(
        "<h5>GRUPO SAN PIO SAS</h5>" +
        "AUTOPISTA MEDELLIN BOGOTA KM 26 + 700 MTS<br />" +
        "Tel: (4) 444-0960<br />" +
        "http://gruposanpio.com/"
      )
      .addTo(masillas);

    L.marker([4.729649, -74.258473], { icon: supermastickIcon })
      .bindPopup(
        "<h5>DEKOAKABADOS</h5>" +
        "Calle 7 N. 1 ESTE 40<br />" +
        "Tel: 3102111588<br />" +
        "www.dekoakabados.com"
      )
      .addTo(masillas);

    L.marker([6.200211, -75.588931], { icon: supermastickIcon })
      .bindPopup(
        "<h5>GRUPO SAN PIO SAS</h5>" +
        "CARRERA 52 # 9B SUR -13<br />" +
        "Tel: (4) 444-0960<br />" +
        "http://gruposanpio.com/"
      )
      .addTo(masillas);

    L.marker([6.158143, -75.616943], { icon: supermastickIcon })
      .bindPopup(
        "<h5>GRUPO SAN PIO SAS</h5>" +
        "CALLE 27 # 41-33<br />" +
        "Tel: (4) 444-0960<br />" +
        "http://gruposanpio.com/"
      )
      .addTo(masillas);

    L.marker([6.242395, -75.570688], { icon: supermastickIcon })
      .bindPopup(
        "<h5>MAYORISTA DE MATERIALES SAS</h5>" +
        "CARRERA 50 CALLE 41- 85<br />" +
        "Tel: (4) 4488284<br />"
      )
      .addTo(masillas)

    L.marker([6.258466, -75.567414], { icon: supermastickIcon })
      .bindPopup(
        "<h5>A VELEZ U & CIA DEPOSITOS MIRANDA LIMITADA</h5>" +
        "CARRERA 52 #59-49<br />" +
        "Tel: (4) 4440044<br />" +
        "www.depositosmiranda.com"
      )
      .addTo(masillas)

    L.marker([6.169225, -75.583641], { icon: supermastickIcon })
      .bindPopup(
        "<h5>JUAN RODRIGO URIBE RUIZ</h5>" +
        "CALLE 36SUR No.37-50<br />" +
        "Tel: 2700753<br />" +
        "www.pinturasuribenvigado.com"
      )
      .addTo(masillas)

    L.marker([4.709529, -74.215102], { icon: supermastickIcon })
      .bindPopup(
        "<h5>CT DRYWALL SAS</h5>" +
        "Carrera 9 (variante Funza-Siberia) # 13-44 <br />" +
        "Tel: 3118739597<br />"
      )
      .addTo(masillas)
      .addTo(pinturas)

    L.marker([4.930220707285889, -74.17277503130379], { icon: supermastickIcon })
      .bindPopup(
        "<h5>DISENSA ALAMCEN LA QUINTA CONSTRUCCION  FERRETERIA LTDA</h5>" +
        "CALLE 5 #3-51<br />" +
        "Tel: 3134944348, 8245876<br />" +
        "E-mail: info-colombia@disensa.com<br />" +
        "Web: www.disensa.com.co"
      )
      .addTo(masillas)
      .addTo(pinturas)

    L.marker([4.302188, -74.80195], { icon: supermastickIcon })
      .bindPopup(
        "<h5>COMERCIALIZADORA DISTRIACOSTA S.A.S</h5>" +
        "carrera 8 # 26-19 Bariio santander<br />" +
        "Tel: 8307399<br />"
      )
      .addTo(masillas);

    L.marker([4.430813, -75.209801], { icon: supermastickIcon })
      .bindPopup(
        "<h5>DISLAYE SAS</h5>" +
        "Avenida mirolindo #60-348<br />" +
        "Tel: 3104764413 -3203402736<br />" +
        "www.dislaye.com"
      )
      .addTo(masillas);

    L.marker([4.298566, -74.802957], { icon: supermastickIcon })
      .bindPopup(
        "<h5>DISLAYE SAS</h5>" +
        "calle 21 # 8 A -41<br />" +
        "Tel: 3174385106 - 3203402736<br />" +
        "www.dislaye.com"
      )
      .addTo(masillas);

    L.marker([4.206067, -74.642559], { icon: supermastickIcon })
      .bindPopup(
        "<h5>DISLAYE SAS</h5>" +
        "carrera 23 # 5-15<br />" +
        "Tel: 3103056563 - 3203402736<br />" +
        "www.dislaye.com"
      )
      .addTo(masillas);

    L.marker([4.814191, -74.353464], { icon: supermastickIcon })
      .bindPopup(
        "<h5>PINTUSEB LTDA.</h5>" + "CR. 6 #7A-28<br />" + "Tel: 8922552<br />"
      )
      .addTo(masillas);

    L.marker([4.149474, -73.632933], { icon: supermastickIcon })
      .bindPopup(
        "<h5>1 A PINTURAS Y MAX COLOR USA S.A.S</h5>" +
        "CARRERA 27 35 A 03 <br />" +
        "Tel: 6624064<br />"
      )
      .addTo(masillas);

    L.marker([5.340631, -72.396568], { icon: supermastickIcon })
      .bindPopup(
        "<h5>ESTUCOS Y VENECIANOS CASANARES SAS</h5>" +
        "CARRERA 20 No 18-10 <br />" +
        "Tel: (8) 6347750 / 6333438<br />" +
        "www.estucosyvenencianos.com"
      )
      .addTo(masillas)
      .addTo(pinturas);

    L.marker([4.863379, -74.061534], { icon: supermastickIcon })
      .bindPopup(
        "<h5>SERVIMEZCLAS CENTER Y CIA LTDA</h5>" +
        "CR. 13 #13-33  <br />" +
        "Tel: 8630584<br />" +
        "www.servimezclaz.com"
      )
      .addTo(masillas);

    L.marker([4.433233, -75.231571], { icon: supermastickIcon })
      .bindPopup(
        "<h5>ARAGON SUAREZ JUAN PABLO</h5>" +
        "calle 25 # 1-39<br />" +
        "Tel: 2661679<br />"
      )
      .addTo(masillas);

    L.marker([4.147661, -73.628916], { icon: supermastickIcon })
      .bindPopup(
        "<h5>INVERSIONES E Y V DEL LLANO S.A.S.</h5>" +
        "cll 31 #23-78 barrio porvenir<br />" +
        "Tel: 6822793<br />"
      )
      .addTo(masillas)

    L.marker([4.905464, -73.943315], { icon: supermastickIcon })
      .bindPopup(
        "<h5>GARZON CASA MIGUEL ANGEL</h5>" +
        "calle 3 N.5-28<br />" +
        "Tel: 8788706<br />"
      )
      .addTo(masillas);

    L.marker([4.553454, -74.534594], { icon: supermastickIcon })
      .bindPopup(
        "<h5>HIPER CENTER MAESTRO E.U</h5>" +
        "cll 8 #04-05 centro<br />" +
        "Tel: 8993817<br />"
      )
      .addTo(masillas)

    L.marker([4.340944, -74.363744], { icon: supermastickIcon })
      .bindPopup(
        "<h5>TECHOS DE PVC COLOMBIA</h5>" +
        "Cra. 9 # 10a -62  / CALLE 10B # 9-41<br />" +
        "Tel: 3232196071- 3155829299<br />" +
        "www.techospvc.com"
      )
      .addTo(masillas);

    L.marker([4.605969, -74.130853], { icon: supermastickIcon })
      .bindPopup(
        "<h5>TECHOS DE PVC COLOMBIA</h5>" +
        "CR.68 #33 SUR -16<br />" +
        "Tel: 3232196071- 3155829299<br />" +
        "www.techospvc.com"
      )
      .addTo(masillas);

    L.marker([4.44068, -75.228987], { icon: supermastickIcon })
      .bindPopup(
        "<h5>TECHOS DE PVC COLOMBIA</h5>" +
        "CARRERA 5 # 23- 25<br />" +
        "Tel: 3232196071- 3155829299<br />" +
        "www.techospvc.com"
      )
      .addTo(masillas);

    L.marker([4.852981, -74.062537], { icon: supermastickIcon })
      .bindPopup(
        "<h5>DISTRIBUIDORA DE MATERIALES H Y M SAS</h5>" +
        "CARRERA 10 #3 42<br />" +
        "Tel: 8708803<br />"
      )
      .addTo(masillas);

    L.marker([5.067664, -74.598431], { icon: supermastickIcon })
      .bindPopup(
        "<h5>SOLUCIONES CONSTRUCTIVAS CHIRIVI SAS</h5>" +
        "calle 2Da #8-75<br />" +
        "Tel: 3112145950 - 3202431174<br />"
      )
      .addTo(masillas);

    L.marker([5.310956, -73.807807], { icon: supermastickIcon })
      .bindPopup(
        "<h5>FERRETERIA ITALY SAS</h5>" +
        "cr 4 #15 A -24  Barrio el estadio<br />" +
        "Tel: 3114683534<br />"
      )
      .addTo(masillas);

    L.marker([4.133544, -73.61309], { icon: supermastickIcon })
      .bindPopup(
        "<h5>INVERSIONES TORCAZA SAS</h5>" +
        "cr. 12 #14-04<br />" +
        "Tel: 6607471<br />"
      )
      .addTo(masillas);

    L.marker([10.986641, -74.815641], { icon: supermastickIcon })
      .bindPopup(
        "<h5>TODOFER DISTRIBUCCIONES SAS</h5>" +
        "Cra 38 No 74-392 Betania <br />" +
        "Tel: 3176420661<br />"
      )
      .addTo(masillas)

    L.marker([10.407232, -75.512483], { icon: supermastickIcon })
      .bindPopup(
        "<h5>DRYWALL DE CARTAGENA S.A.S.</h5>" +
        "Calle 30 No. 44 A 47 local 2 España <br />" +
        "Tel: 3008663173<br />"
      )
      .addTo(masillas)
      .addTo(pinturas)


    L.marker([11.529427, -72.904159], { icon: supermastickIcon })
      .bindPopup(
        "<h5>INVERSIONES LOZADA SAS</h5>" +
        "Calle 34 No. 7 K 30 <br />" +
        "Tel: 3017898550<br />"
      )
      .addTo(masillas)

    L.marker([10.457463, -73.256199], { icon: supermastickIcon })
      .bindPopup(
        "<h5>DRYWALL DEL CESAR</h5>" +
        "Diag. 21 No. 23- 16 Valledupar <br />" +
        "Tel: 3106404040<br />"
      )
      .addTo(masillas)
      .addTo(pinturas);

    L.marker([10.387615, -75.506248], { icon: supermastickIcon })
      .bindPopup(
        "<h5>SUPERDRYWALL SAS</h5>" +
        "Tranv 54 No. 28 A 78 Ceballos<br />" +
        "Tel: 6673294<br /> "
      )
      .addTo(masillas)
      .addTo(pinturas)


    L.marker([10.937057, -74.779705], { icon: supermastickIcon })
      .bindPopup(
        "<h5>BARRERA CALDERON ALVARO</h5>" +
        "Calle 19 No. 1 F -04 barrio Imon Bolivar <br />" +
        "Tel: 3269327<br /> "
      )
      .addTo(masillas);

    L.marker([10.998352, -74.819272], { icon: supermastickIcon })
      .bindPopup(
        "<h5>FERRETERIA DON KIKE SAS</h5>" +
        "Calle 43 No.43 -130  <br />" +
        "Tel: 3157283610<br /> " +
        "www.ferreteriadonkike.com"
      )
      .addTo(masillas)

    L.marker([10.981694, -74.794379], { icon: supermastickIcon })
      .bindPopup(
        "<h5>CONSTRU-CEM S.A.S</h5>" +
        "Calle 54 No 38-09  Recreo <br />" +
        "Tel: 3707777<br /> " +
        "www.construcem.com"
      )
      .addTo(masillas)

    L.marker([11.006317, -74.791079], { icon: supermastickIcon })
      .bindPopup(
        "<h5>SUPER KIKE SAS</h5>" +
        "Calle 70 No. 70-100<br />" +
        "Tel: 3157283610<br /> " +
        "www.ferreteriasuperkike.com"
      )
      .addTo(masillas)

    L.marker([4.536031, -76.10367], { icon: supermastickIcon })
      .bindPopup(
        "<h5>MONICA VIVIANA LONDOÑO VASQUEZ / DRYWALLDECOR LDV</h5>" +
        "Carrera 16 #19-12 <br />" +
        "Tel: 2292780<br /> "
      )
      .addTo(masillas)
      .addTo(pinturas)


    L.marker([10.389376, -75.47634], { icon: supermastickIcon })
      .bindPopup(
        "<h5>F. CONSTRUDRYWALL S.A.S</h5>" +
        "Calle 31 No. 71 B - 89 La Concepcion <br />" +
        "Tel: 3174274147<br /> "
      )
      .addTo(masillas)
      .addTo(pinturas);

    L.marker([10.997216, -74.81556], { icon: supermastickIcon })
      .bindPopup(
        "<h5>DISTRIBUCIONES  S Y E SAS </h5>" +
        "Calle 80 No 43.15 <br />" +
        "Tel: 3503800<br /> "
      )
      .addTo(masillas);

    L.marker([10.384117, -75.468362], { icon: supermastickIcon })
      .bindPopup(
        "<h5>BIGDRYWALL SAS </h5>" +
        "Barrio Villa Sol Calle 30 No. 82-245 Ternera <br />" +
        "Tel: 3175740120<br /> "
      )
      .addTo(masillas)
      .addTo(pinturas);

    L.marker([8.759174, -75.878781], { icon: supermastickIcon })
      .bindPopup(
        "<h5>ARGUMEDO DIAZ ABEL </h5>" +
        "Cra 8 No. 37-20 Barrio Centro <br />" +
        "Tel: 3145732133<br /> "
      )
      .addTo(masillas)

    L.marker([10.429218, -75.534489], { icon: supermastickIcon })
      .bindPopup(
        "<h5>ESPINOSA VEGA ALVARO MAURICIO </h5>" +
        "Torices Cra 17 No 42-17 <br />" +
        "Tel: 6581099<br /> "
      )
      .addTo(masillas)
      .addTo(pinturas);

    L.marker([10.997721, -74.808076], { icon: supermastickIcon })
      .bindPopup(
        "<h5>RUEDA SANTOYO JAIME</h5>" +
        "calle 75 No. 48-13<br />" +
        "Tel: 3607051<br /> "
      )
      .addTo(masillas);

    L.marker([10.401149, -75.51905], { icon: supermastickIcon })
      .bindPopup(
        "<h5>INVERDRYWALL SAS</h5>" +
        "Av Crisanto Luque Dia 22 No 44-93 Bruselas<br />" +
        "Tel: 6627307<br /> "
      )
      .addTo(masillas)

    L.marker([10.990743, -74.769302], { icon: supermastickIcon })
      .bindPopup(
        "<h5>YESO Y CAOLINES DE CARTAGENA  </h5>" +
        "Tranversal 54  Mz 42 Lt 05 Baarrio el Pozon <br />" +
        "Tel: 6415722<br /> "
      )
      .addTo(masillas)

    L.marker([10.405812, -75.500284], { icon: supermastickIcon })
      .bindPopup(
        "<h5>RESINAPLASS  </h5>" +
        "Avenida Pedro de Heredia Sector Tesca <br />" +
        "Tel: 6697818<br /> "
      )
      .addTo(masillas)

    L.marker([10.423316, -75.546341], { icon: supermastickIcon })
      .bindPopup(
        "<h5>CONSTRUCENTER SC SAS  </h5>" +
        "Av Centenario No. 31-50 centro Amurallado<br />" +
        "Tel: 6648267<br /> "
      )
      .addTo(masillas)

    L.marker([10.403808, -75.520017], { icon: supermastickIcon })
      .bindPopup(
        "<h5>PLACAS Y ACABADOS SAS  </h5>" +
        "Avenida Crisanto Luque Diagonal 22 No. 4-85 <br />" +
        "Tel: 6623685<br /> "
      )
      .addTo(masillas);

    L.marker([11.011549, -74.797883], { icon: supermastickIcon })
      .bindPopup(
        "<h5>TODOLAMINA & COMPANIA S.A.</h5>" +
        "Carrera 70 No 104 bodega 1<br />" +
        "Tel: 3688664<br /> "
      )
      .addTo(masillas);

    L.marker([10.390661, -75.478384], { icon: supermastickIcon })
      .bindPopup(
        "<h5>MATERIALES SU CASA</h5>" +
        "Diagonal 31 No. 71-14 Sector Providencia<br />" +
        "Tel: 6627295<br /> "
      )
      .addTo(masillas)

    L.marker([10.390364, -75.492304], { icon: supermastickIcon })
      .bindPopup(
        "<h5>FERRETERIA CONSTRUCTORA L & R LTDA</h5>" +
        "Transv 54 No. 46-35 sector Buenos Aires <br />" +
        "Tel: 6678372<br /> "
      )
      .addTo(masillas)

    L.marker([10.407522, -75.496522], { icon: supermastickIcon })
      .bindPopup(
        "<h5>ELECTRICOS Y PINTURAS OLAYA, SAS</h5>" +
        "Av Olaya Principal No. 53-22  <br />" +
        "Tel: 6698336<br /> "
      )
      .addTo(masillas)
      .addTo(pinturas);

    L.marker([10.409976, -75.517823], { icon: supermastickIcon })
      .bindPopup(
        "<h5>MULANO GROUP SAS</h5>" +
        "Av Pedro Heredia No. 33-06 Prado  <br />" +
        "Tel: 3013245500<br /> "
      )
      .addTo(masillas)

    L.marker([10.464183, -73.247587], { icon: supermastickIcon })
      .bindPopup(
        "<h5>INVERSIONES JJSM SAS</h5>" +
        "Carrera 15 No.20 A 44 Local 08 La Granja<br />" +
        "Tel: 3153639359<br /> "
      )
      .addTo(masillas)
      .addTo(pinturas)

    L.marker([11.230132, -74.195132], { icon: supermastickIcon })
      .bindPopup(
        "<h5>DISTRIBUIDORA IXSA</h5>" +
        "Cra 19-29 D1 -04  <br />" +
        "Tel: 4351633<br /> "
      )
      .addTo(masillas)

    L.marker([10.470072, -73.243037], { icon: supermastickIcon })
      .bindPopup(
        "<h5>CODRYWALL</h5>" +
        "Calle 17 No. 19 D-15 Barrio la Esperanza  <br />" +
        "Tel: 3106315530<br /> "
      )
      .addTo(masillas)
      .addTo(pinturas)

    L.marker([11.245685, -74.204304], { icon: supermastickIcon })
      .bindPopup(
        "<h5>ISBELIA RODRIGUEZ BECERRA</h5>" +
        "Calle 11 No. 11-42 Mercado Publico  <br />" +
        "Tel: 4316822<br /> "
      )
      .addTo(masillas)
      .addTo(pinturas)

    L.marker([3.440297, -76.459863], { icon: supermastickIcon })
      .bindPopup(
        "<h5>COLPERFILES SAS</h5>" +
        "KM 1,5 Vía Candelaria Condominio Industrial La Nubia II BG 68  <br />" +
        "Tel: 4350011<br /> " +
        "www.colperfiles.com"
      )
      .addTo(masillas, pinturas);

    L.marker([4.536621, -75.677657], { icon: supermastickIcon })
      .bindPopup(
        "<h5>GARCIA ARCILA LUIS FERNANDO- FERCEMENTOS</h5>" +
        "CARRERA 22# 21-50  <br />" +
        "Tel: 3104517339<br /> "
      )
      .addTo(masillas);

    L.marker([4.536336, -75.676784], { icon: supermastickIcon })
      .bindPopup(
        "<h5>RAMIREZ TABARES JOHAN ANDRES</h5>" +
        "CARRERA  21 # 20-51   <br />" +
        "Tel: 7475480<br /> "
      )
      .addTo(masillas)

    L.marker([4.526956, -75.682396], { icon: supermastickIcon })
      .bindPopup(
        "<h5>CONSTRUDRYWALL DE OCCIDENTE SAS</h5>" +
        "CARRERA 19# 35-27   <br />" +
        "Tel: 7411240<br /> "
      )
      .addTo(masillas)

    L.marker([4.750281, -75.919511], { icon: supermastickIcon })
      .bindPopup(
        "<h5>LONDOÑO JUAN FDO Y/O FERREMATERIALES LA ROCA</h5>" +
        "Cra 4 # 17-02 Barrio El Llano <br />" +
        "Tel: 2142220<br /> "
      )
      .addTo(masillas)
      .addTo(pinturas)

    L.marker([1.210143, -77.275638], { icon: supermastickIcon })
      .bindPopup(
        "<h5>FERRETERIA J ALBERTO  SAS</h5>" +
        "Carrera 20 #19 - 75  <br />" +
        "Tel: 7200054<br /> "
      )
      .addTo(masillas)
      .addTo(pinturas);

    L.marker([4.810299, -75.698207], { icon: supermastickIcon })
      .bindPopup(
        "<h5>PINTURAS PANELTON Y DISTRIBUCIONES S.A.S.</h5>" +
        " AVENIDA 30 DE AGOSTO 87 110  <br />" +
        "Tel: 3131725<br /> "
      )
      .addTo(masillas)

    L.marker([3.503672, -76.516293], { icon: supermastickIcon })
      .bindPopup(
        "<h5>COVAL</h5>" +
        "Cr 35 # 10 – 592 Parque Empresarial Nueva Era Bodega 7 Sector Industrial Arroyohondo Yumbo Valle Colombia <br />" +
        "http://coval.com.co"
      )
      .addTo(masillas);

    L.marker([8.309756, -73.61239], { icon: supermastickIcon })
      .bindPopup(
        "<h5>DRYWALL Y TEJAS DEL CESAR</h5>" +
        "Calle 5 No 22 - 55 Barrio Centro <br />" +
        "Tel: 5-5657701"
      )
      .addTo(masillas)

    L.marker([7.104886, -73.117321], { icon: supermastickIcon })
      .bindPopup(
        "<h5>CASTRO TAMAYO LUIS EVELIO</h5>" +
        "Calle 61 No 17 A - 54 Barrio la Ceiba <br />" +
        "Tel: 3177578434"
      )
      .addTo(masillas);

    L.marker([7.132128, -73.130336], { icon: supermastickIcon })
      .bindPopup(
        "<h5>PINTUMEZCLAS COLOMBIA SAS</h5>" +
        "Carrera 15 No 15 - 20 Barrio Gaitan <br />" +
        "Tel: 7-6717090"
      )
      .addTo(masillas)

    L.marker([7.104439, -73.118436], { icon: supermastickIcon })
      .bindPopup(
        "<h5>FERRETERIA LA CASITA</h5>" +
        "Calle 61 No 16 - 46 Barrio Ricaurte <br />" +
        "Tel: 7-6411959"
      )
      .addTo(masillas)

    L.marker([7.10588, -73.116122], { icon: supermastickIcon })
      .bindPopup(
        "<h5> CONDECORANDO S.A.S </h5>" +
        "Carrera 17E # 60 - 31 <br />" +
        "Tel: 7-6447408"
      )
      .addTo(masillas)
      .addTo(pinturas)


    L.marker([5.613371, -73.818091], { icon: supermastickIcon })
      .bindPopup(
        "<h5> SERVIMEZCLAS CENTER Y CIA LTDA </h5>" +
        "Carrera 8 # 11 - 104<br />" +
        "Tel: 8-7261439"
      )
      .addTo(masillas);

    L.marker([7.884366, -72.507257], { icon: supermastickIcon })
      .bindPopup(
        "<h5>FERRETITO SAS</h5>" +
        "Calle 12 No 8 - 62 Centro <br />" +
        "Tel: 7-5730009"
      )
      .addTo(masillas)

    L.marker([7.881505, -72.502841], { icon: supermastickIcon })
      .bindPopup(
        "<h5>SERVIMEX</h5>" +
        "Avenida 5 No 15 - 49 Barrio la cabrera <br />" +
        "Tel: 3143618946"
      )
      .addTo(masillas);

    L.marker([5.825892, -73.028519], { icon: supermastickIcon })
      .bindPopup(
        "<h5>INGEMEC SAS</h5>" +
        "Calle 20 # 20 - 58 <br />" +
        "Tel: 8-7616706" +
        "www.ingemec.com.co"
      )
      .addTo(masillas)
      .addTo(pinturas);

    /*L.marker([6.982758, -73.051983], { icon: supermastickIcon })
      .bindPopup('<h5>ROGER DRYWALL </h5>' +
        'Carrera 4 No 12 - 11 <br />' +
        'Tel: 3112553871')*/

    L.marker([7.070951, -73.172205], { icon: supermastickIcon })
      .bindPopup(
        "<h5>DEPOSITO LA CALERA</h5>" +
        "Carrera 29 #  34A - 28 <br />" +
        "Tel: 7-6465640" +
        "www.depositolacalera.com"
      )
      .addTo(masillas)
      .addTo(pinturas);

    L.marker([7.083915, -73.152334], { icon: supermastickIcon })
      .bindPopup(
        "<h5>CHP MATERIALES PARA CONSTRUCCION S.A.</h5>" +
        "Carrera 5 No. 58 - 12 Vía Giron - Bucaramanga <br />" +
        "Tel: 7-6469466" +
        "www.chpcentroferretero.com"
      )
      .addTo(masillas);

    L.marker([7.088536, -73.103528], { icon: supermastickIcon })
      .bindPopup(
        "<h5>ARDISA MATERIALES</h5>" +
        "Carrera 33 #112-43/47<br />" +
        "Tel: 6444744-6301813<br />" +
        "https://ardisa.com/"
      )
      .addTo(masillas)

    L.marker([7.105132, -73.116815], { icon: supermastickIcon })
      .bindPopup(
        "<h5>ARDISA MATERIALES</h5>" +
        "Carrera 17c #60-30 <br />" +
        "Tel: 6444744-6301813<br />" +
        "https://ardisa.com/"
      )
      .addTo(masillas)


    L.marker([5.93078, -73.615799], { icon: supermastickIcon })
      .bindPopup(
        "<h5>ASID</h5>" +
        "Calle 10 No 13 - 04 Barrio Centro <br />" +
        "Tel: 7-7486951"
      )
      .addTo(masillas)
      .addTo(pinturas);

    L.marker([6.988648, -73.051248], { icon: supermastickIcon })
      .bindPopup(
        "<h5>PUNTO DEL HIERRO</h5>" +
        "Calle 6 No. 7 - 06 San Rafael <br />" +
        "Tel: 7-6551594"
      )
      .addTo(masillas)
      .addTo(pinturas);

    L.marker([7.758541, -73.396661], { icon: supermastickIcon })
      .bindPopup(
        "<h5>DISTRIBUCIONES TITAN SAS</h5>" +
        "Diagonal 2C No. 7a - 65 San Alberto <br />" +
        "Tel: 3157558258"
      )
      .addTo(masillas)
      .addTo(pinturas);

    L.marker([6.559199, -73.141542], { icon: supermastickIcon })
      .bindPopup(
        "<h5>FERRETERIA CONSTRUMART</h5>" +
        "Avenida Santander No 22 - 07 Centro <br />" +
        "Tel: 7237070"
      )
      .addTo(masillas)

    L.marker([5.713081, -72.928037], { icon: supermastickIcon })
      .bindPopup(
        "<h5> INGEMEC SAS </h5>" +
        "Carrera 10 # 9 - 64  <br />" +
        "Tel: 8-7704031" +
        "www.ingemec.com.co"
      )
      .addTo(masillas)
      .addTo(pinturas);

    L.marker([5.532513, -73.366632], { icon: supermastickIcon })
      .bindPopup(
        "<h5> ARMATODO </h5>" +
        "Carrera 14 # 18 - 34  <br />" +
        "Tel: 8-7433386"
      )
      .addTo(masillas)
      .addTo(pinturas);

    L.marker([5.574292, -73.340722], { icon: supermastickIcon })
      .bindPopup(
        "<h5>  MULTIOBRAS </h5>" +
        "Calle 77 # 6 - 15<br />" +
        "Tel: 8-7456302" +
        "www.multiobras.com"
      )
      .addTo(masillas);

    L.marker([5.560021, -73.34895], { icon: supermastickIcon })
      .bindPopup(
        "<h5> FERROPAZ </h5>" +
        "Av. Norte Cra 6 # 52 - 09<br />" +
        "Tel: 8-7456043" +
        "www.ferropaz.com"
      )
      .addTo(masillas);

    // Internacionales

    L.marker([9.96657, -84.005153], { icon: supermastickIcon })
      .bindPopup(
        "<h5>Mayoreo del Istmo S.A</h5>" +
        "218, San José Province, Guadalupe, Costa Rica<br />" +
        "Tel: (506) 2292 9370<br />" +
        "http://mayoreodelistmocr.com/"
      )
      .addTo(masillas);

    L.marker([9.980721, -84.167952], { icon: supermastickIcon })
      .bindPopup(
        "<h5>Eurorastack CR SA</h5>" +
        "La Asunción De Belén De Waterland 50 Oeste Y 75 Norte, Heredia - San José De Costa Rica.<br />" +
        "Tel: (506) 2519 1500<br />" +
        "http://www.ramstackcr.com"
      )
      .addTo(masillas);

    L.marker([9.909416, -84.047227], { icon: supermastickIcon })
      .bindPopup(
        "<h5>TECNI GYPSUM SA</h5>" +
        "San Francisco de Dos Ríos, 250m al este de la Bomba la Pacífica. San José, Costa Rica.<br />" +
        "Tel: (506) 2217 4200<br />" +
        "https://tecnigypsum.com"
      )
      .addTo(masillas);

    L.marker([14.084856, -87.172233], { icon: supermastickIcon })
      .bindPopup(
        "<h5>TECNI GYPSUM SAPOLARIS INTERNACIONAL</h5>" +
        "Zona Kennedy, calle principal a Unitec frente a laboratorios Andifar. Tegucigalpa, Honduras<br />" +
        "Tel: (504) 2255 6088 / 6059<br />" +
        "http://www.polarisinternacional.com"
      )
      .addTo(masillas);

    L.marker([9.045088, -79.469465], { icon: supermastickIcon })
      .bindPopup(
        "<h5>AC1 SUPPLY PANAMA S.A</h5>" +
        "Vía Tocumen, Centro Industrial El Crisol, Galera N°12, Ciudad de Panamá<br />" +
        "(506) 217 6792 / 93 / 94<br />" +
        "http://ac1supplypa.com"
      )
      .addTo(masillas);

    L.marker([9.032368, -79.53217], { icon: supermastickIcon })
      .bindPopup(
        "<h5>COCHEZ Y COMPAÑÍA SA</h5>" +
        "Av. BALBOA FINAL, Ave ELOY ALFARO 15-33, PANAMA, REPUBLICA DE PANAMA<br />" +
        "(507) 302 4444<br />" +
        "https://www.cochezycia.com"
      )
      .addTo(masillas)


    L.marker([8.976595, -79.515053], { icon: supermastickIcon })
      .bindPopup(
        "<h5>DISTRIFASTEX GP, SA</h5>" +
        "EL INGENIO AV. LA PAZ PH PLAZA 100, LOCAL 7 AL LADO DEL BANCO  GENERAL, BETHANIA - CIUDAD DE PANAMÁ<br />" +
        "(507) 261 7586<br />" +
        "www.distrifastexgp.com"
      )
      .addTo(masillas)


    L.marker([14.590976, -90.558789], { icon: supermastickIcon })
      .bindPopup(
        "<h5>PRISMA MATERIALES SA</h5>" +
        "1a Ave. 33-67 zona 12, Col. el Carmen Guatemala<br />" +
        "(502) 23012200<br />" +
        "https://prismateriales.com"
      )
      .addTo(masillas);

    L.marker([-34.886201, -56.177359], { icon: supermastickIcon })
      .bindPopup(
        "<h5>BARRACA PARANÁ S.A.</h5>" +
        "DEMOCRACIA 2354<br />" +
        "(598) 2200 0845<br />" +
        "https://www.barracaparana.com"
      )
      .addTo(masillas);

    L.marker([-34.878078, -56.106686], { icon: supermastickIcon })
      .bindPopup(
        "<h5>ONTILPLACK SA</h5>" +
        "IGUA 4715 MONTEVIDEO, URUGUAY CP. 11400<br />" +
        "(598) 2525 0189<br />" +
        "www.ontil.com.uy"
      )
      .addTo(masillas);

    L.marker([-34.890432, -56.057104], { icon: supermastickIcon })
      .bindPopup(
        "<h5>RACELY SA</h5>" +
        "GRAL FLORES, 3196, MONTEVIDEO, URUGUAY. CP 11600<br />" +
        "(598) 2204 5858<br />" +
        "https://www.secocenter.com"
      )
      .addTo(masillas);

    L.marker([-16.544403, -68.065131], { icon: supermastickIcon })
      .bindPopup(
        "<h5>CREATICA SUPPLIERS SRL</h5>" +
        "CALLE 27 C # 20 ZONA LOS PINOS<br />" +
        "(591) 2227 87921<br />"
      )
      .addTo(masillas);

    L.marker([-17.771157, -63.143704], { icon: supermastickIcon })
      .bindPopup(
        "<h5>TECNO PLASTICA ORIENTAL SA</h5>" +
        "PARQUE INDUSTRIAL MZ-5  ZONA ESTE SANTA CRUZ DE LA SIERRA BOLIVIA<br />" +
        "(5913) 3464718<br />" +
        "https://www.tecnopor.net"
      )
      .addTo(masillas);

    L.marker([18.476113, -69.954704], { icon: supermastickIcon })
      .bindPopup(
        "<h5>CIELOS ACUSTICOS SRL</h5>" +
        "Av. Charles Sumner #35, Los Prados<br />" +
        "(809) 732-2368<br />" +
        "http://cielosacusticos.com"
      )
      .addTo(masillas);

    L.marker([18.494444, -69.894955], { icon: supermastickIcon })
      .bindPopup(
        "<h5>P&L DECORACIONES SRL</h5>" +
        "AV. JOSEFA BREA #186, VILLA MARIA , SANTO DOMINGO - REPUBLICA DOMINICANA<br />" +
        "(809) 684 3277<br />"
      )
      .addTo(masillas);

    L.marker([18.469715, -69.924614], { icon: supermastickIcon })
      .bindPopup(
        "<h5>TRACKS SRL</h5>" +
        "KM 5 ½ CARRETERA DUARTE LICEY, SANTIAGO, REPUBLICA DOMINICANA<br />" +
        "(809) 970 5656<br />" +
        "www.trackssa.com"
      )
      .addTo(masillas);

    L.marker([19.45758, -70.70184], { icon: supermastickIcon })
      .bindPopup(
        "<h5>FERRETERIA OCHOA</h5>" +
        "AVENIDA IMBERT #53, CP. 51000 SANTIAGO, REPÚBLICA DOMINICANA 03<br />" +
        "(809) 971-8000<br />" +
        "https://www.ochoa.com.do"
      )
      .addTo(masillas);

    L.marker([18.481557, -69.925908], { icon: supermastickIcon })
      .bindPopup(
        "<h5>INGENIERIA Y AIRE ACONDICIONADO S.A.</h5>" +
        "HERIBERTO PIETER N. 46 ENSANCHE NACO SANTO DOMINGO, REPUBLICA DOMINICANA<br />" +
        "(809) 566 8170<br />"
      )
      .addTo(masillas);

    L.marker([4.625323, -74.168939], { icon: supermastickIcon })
      .bindPopup(
        "<h5>PEGASTILOS</h5>" +
        "CRA 81 D No 42 A - 58 SUR , BARRIO VILLA DE LA TORRE <br />" +
        "3105681986<br />"
      )
      .addTo(masillas);

    L.marker([4.503324, -74.110857], { icon: supermastickIcon })
      .bindPopup(
        "<h5>SAAVEDRA SEDANO MARIA DEL CARMEN</h5>" +
        "CL 91 SUR No 6A- 12 ESTE <br />" +
        "Tel: 7630430<br />"
      )
      .addTo(masillas);

    L.marker([17.514929, -88.221623], { icon: supermastickIcon })
      .bindPopup(
        "<h5>IMEX BZ LLC</h5>" +
        "74 Bella Vista Belize City, Belize<br />" +
        "(501) 223 5200<br />" +
        "https://www.imexbz.com"
      )
      .addTo(masillas)

    L.marker([18.427431, -66.137433], { icon: supermastickIcon })
      .bindPopup(
        "<h5>ACHA BUILDING SUPPLY, INC</h5>" +
        "500 Carr # PR 5 Bo. Puente Blanco Cataño, PR 00962<br />" +
        "(787) 275 6095 <br />" +
        "http://www.achatrading.com/"
      )
      .addTo(masillas);

    L.marker([18.384288, -66.06555], { icon: supermastickIcon })
      .bindPopup(
        "<h5>ALL INTERIORS INC</h5>" +
        "CARRETERA 8838 #1539 SECTOR EL CINCO, RIO PIEDRAS, PUERTO RICO 00926<br />" +
        "(787) 765 6420<br />"
      )
      .addTo(masillas);

    L.marker([-2.197789, -79.930766], { icon: supermastickIcon })
      .bindPopup(
        "<h5>SISTEMAS CONSTRULIVIANOS CIA</h5>" +
        "Avellanas 4W y El Juncal, Quito-Ecuador<br />" +
        "(593)02346 4339<br />" +
        "www.siconecuador.com"
      )
      .addTo(masillas);

    L.marker([10.489840, -66.855165], { icon: supermastickIcon })
      .bindPopup(
        "<h5>RAPIDFIX COMERCIALIZADORA C.A.</h5>" +
        "Calle Los Ángeles local Aki, Chacao<br />" +
        "Tel. 2660701, 2658045<br />" +
        "rapidfixcom@gmail.com"
      )
      .addTo(masillas);

    L.marker([10.96707, -74.838054], { icon: supermastickIcon })
      .bindPopup(
        "<h5>SOLUCENTER COLOMBIA S.A.S.</h5>" +
        "AV CIRCUNVALAR CALLE 109 B #12E-18<br />" +
        "Tel 3092072 / 3092069<br />"
      )
      .addTo(masillas)
      .addTo(pinturas)

    const capaBase = L.tileLayer(
      "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      {
        attribution:
          '© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      }
    );

    const map = L.map("map", {
      center: [lat, lng],
      zoom: 12,
      layers: [capaBase, masillas],
    });
    const markers = new L.FeatureGroup().addTo(map);

    const productos = {
      Masillas: masillas,
      Pinturas: pinturas
    };
    L.control.layers(productos).addTo(map);

    document
      .getElementById("selectorCiudad")
      .addEventListener("change", selectorCiudad);

    function selectorCiudad(e) {
      let coords = e.target.value.split(",");
      map.flyTo(coords, 14);
    }

    document.getElementById("inter").addEventListener("change", inter);

    function inter(e) {
      let coords = e.target.value.split(",");
      map.flyTo(coords, 14);
    }

    // Buscador
    const search = document.querySelector("#search");
    search.addEventListener("input", buscarDireccion);
    /*document.addEventListener('DOMContentLoaded', () => {
    })*/

    function buscarDireccion(e) {
      if (e.target.value.length > 8) {
        // limpiar icono preexistente del mapa
        if (marker) {
          map.removeLayer(marker);
        }
        // usar el buscador y geocoder
        //const geocodeService = L.esri.Geocoding.geocodeService();

        const provider = new OpenStreetMapProvider();
        provider.search({ query: e.target.value }).then((resultado) => {
          map.setView(resultado[0].bounds[0]);
          // marker

          marker = new L.marker(resultado[0].bounds[0], {
            draggable: true,
            autoPan: true,
          })
            .addTo(map)
            .bindPopup(resultado[0].label)
            .openPopup();

          //asignar contenedor
          markers.addLayer(marker);
          // detectar coordenadas
          marker.on("moveend", function (e) {
            marker = e.target;
            const position = marker.getLatLng();
            map.panTo(new L.LatLng(position.lat, position.lng));
            /*geocodeService.reverse().latlng(position, 15).run(function (error, result) {
              console.log(result);
            })*/
          });
          /*geocodeService.reverse().latlng(resultado[0].bounds[0], 15).run(function (error, result) {
            console.log(result);
            // mostrar mapa segun busqueda
          })*/
        });
      }
    }
  }
}
