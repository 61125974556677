import { Component, HostBinding, OnInit } from "@angular/core";

import {
  trigger,
  state,
  style,
  animate,
  group,
  query,
  transition,
  // ...
} from '@angular/animations';
import { RouterOutlet, Router, NavigationEnd } from '@angular/router';
import "hammerjs";
import { fader, slideInAnimation, slideInOut } from './animations';
import { AuthService } from './services/auth.service';
import { JwtHelperService } from '@auth0/angular-jwt';
declare let L;



@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
  animations: [
    fader
  ]
})

export class AppComponent implements OnInit {
  title = "supermastickFront";
  jwtHelper = new JwtHelperService();

  constructor(private router: Router,
    private authService: AuthService) { }

  @HostBinding('@.disabled')
  public animationsDisabled = true;

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }

  toggleAnimations() {
    this.animationsDisabled = !this.animationsDisabled;
  }

  ngOnInit() {
    const token = localStorage.getItem('token');
    if (token) {
      this.authService.decodedToken = this.jwtHelper.decodeToken(token);
    }

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });
  }

}