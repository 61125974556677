import { Injectable } from '@angular/core';
import { Users } from '../models/user';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Global } from './global';


@Injectable()
export class UsuarioService {
  public url: string;
  usuario: Users;
  dialog: false;
  idusuario: any;
  search: '';
  constructor(
    private http: HttpClient,
  ) {
    this.url = Global.url;
  }
  tomarUsuarios(): Observable<any> {
    const usuarios = 'usuarios/listar';
    const header = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    return this.http.get(this.url + usuarios, { headers: header });
  }
  tomarUsuario(id): Observable<any> {
    const usuario = 'publicacions/mostrar/';
    return this.http.get(this.url + usuario + id);
  }
  activar(id: any): Observable<any> {
    const usuario = 'usuarios/activar/';
    return this.http.put(this.url + usuario + id, {});
  }
  desactivar(id: any): Observable<any> {
    const usuarios = 'usuarios/desactivar/';
    return this.http.put(this.url + usuarios + id, {});
  }
  busqueda(searchString): Observable<any> {
    const busqueda = 'publicacions/buscar/';
    return this.http.get(this.url + busqueda + searchString);
  }
  crear(usuario: any): Observable<any> {
    const crear = 'publicacions/crearpublicaciones/';
    return this.http.post<any>(this.url + crear, usuario);
  }
}
