import { Component, OnInit, Input, ViewChild, Inject } from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { UsuarioService } from "../../../../services/user.service";
import { Users } from "../../../../models/user";
import { Global } from "../../../../services/global";
import { MatTableDataSource, MatSort, MatPaginator } from "@angular/material";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { AlertifyService } from "../../../../services/alertify.service";
import { AuthService } from "../../../../services/auth.service";

@Component({
  selector: "app-usuarios",
  templateUrl: "./usuarios.component.html",
  styleUrls: ["./usuarios.component.css"],
  providers: [UsuarioService],
})
export class UsuariosComponent implements OnInit {
  color = "primary";
  model: any = {};
  usuarios: any;
  checked = false;
  disabled = false;
  status = false;
  public url: string;
  public searchKey: any;
  public listData: MatTableDataSource<any>;

  displayedColumns: string[] = [
    "rol",
    "nombre",
    "contacto",
    "telefono",
    "email",
    "condicion",
    "actions",
  ];
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  // @Input() usuarios: Users[];
  constructor(
    public dialog: MatDialog,
    private userService: UsuarioService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private alertify: AlertifyService
  ) {
    this.url = Global.url;
  }

  ngOnInit() {
    this.obtenerUsuarios();
  }

  onSearchClear() {
    this.searchKey = "";
  }
  applyFilter() {
    this.listData.filter = this.searchKey.trim().toLowerCase();
  }
  obtenerUsuarios() {
    this.userService.tomarUsuarios().subscribe(
      (response) => {
        if (response.status === "success") {
          this.usuarios = response.usuarios;
        }
        this.usuarios = response;
        this.listData = new MatTableDataSource(this.usuarios);
        this.listData.sort = this.sort;
        this.listData.paginator = this.paginator;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  activar(idusuario: any) {
    this.userService.activar(idusuario).subscribe(
      (response) => {
        if (response) {
          this.usuarios = response;
        }
      },
      (error) => {
        console.log(error.message);
      }
    );
  }
  desactivar(idusuario: any) {
    this.userService.desactivar(idusuario).subscribe(
      (response) => {
        if (response) {
          this.usuarios = response;
        }
      },
      (error) => {
        console.log(error.message);
      }
    );
  }

  changeCondition(user: any) {
    if (user.condicion) {
      this.desactivar(user.idusuario);
      this.alertify.success("Usuario desactivado!");
    } else {
      this.activar(user.idusuario);
      this.alertify.success("Usuario activado!");
    }
  }
  openDialog() {
    const dialogRef = this.dialog.open(ModalUsuario);

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }
}

@Component({
  selector: "modal-usuario",
  templateUrl: "modal-usuario.html",
  styleUrls: ["./usuarios.component.css"],
})
export class ModalUsuario implements OnInit {
  model: any = {};
  usuarios: any;
  constructor(
    private authService: AuthService,
    private alertify: AlertifyService,
    public dialogRef: MatDialogRef<ModalUsuario>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
  public ngOnInit(): void {}
  crear() {
    this.authService.crear(this.model).subscribe(
      (next) => {
        this.alertify.success("Usuario creado correctamente!");
      },
      (error) => {
        this.alertify.error("Error en el registro! ");
      }
    );
  }
}
