import { DashComponent } from "./components/instaladores/dash/dash.component";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule, ApplicationRef } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";
import { MatDialogModule } from "@angular/material/dialog";
import { routing, appRoutingProviders } from "./app-routing";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LeafletModule } from "@asymmetrik/ngx-leaflet";
import { OpenStreetMapProvider } from "leaflet-geosearch";
import { getSpanishPaginatorIntl } from "./traductorPag";
import { MomentModule } from "angular2-moment";
import { AngularFileUploaderModule } from "angular-file-uploader";
import { FroalaEditorModule, FroalaViewModule } from "angular-froala-wysiwyg";
import "froala-editor/js/plugins.pkgd.min.js";
import "froala-editor/js/languages/de.js";
import { EditorModule, TINYMCE_SCRIPT_SRC } from "@tinymce/tinymce-angular";
import * as $ from "jquery";

import { HomeComponent } from "./components/home/home.component";
import { MainMenuComponent } from "./main-menu/main-menu.component";
import { LayoutModule } from "@angular/cdk/layout";
import { MaterialModule } from "./material.module";
import { LoginComponent } from "./components/login/login.component";
import { MasillasComponent } from "./components/productos/masillas/masillas.component";
import { GeneralComponent } from "./components/productos/general/general.component";
import { InterioresComponent } from "./components/productos/masillas/interiores/interiores.component";
import { ExterioresComponent } from "./components/productos/masillas/exteriores/exteriores.component";
import { AdhesivosComponent } from "./components/productos/adhesivos/adhesivos.component";
import { PinturasComponent } from "./components/productos/pinturas/pinturas.component";
import { PanelreyComponent } from "./components/productos/placas/panelrey/panelrey.component";
import { PermabaseComponent } from "./components/productos/placas/permabase/permabase.component";
import { ProyectosComponent } from "./components/proyectos/proyectos.component";
import { RegistroComponent } from "./components/distribuidores/registro/registro.component";
import { RegistroinsComponent } from "./components/instaladores/registro/registro.component";
import { MapaComponent } from "./components/distribuidores/mapa/mapa.component";
import { CalculadoraComponent } from "./components/instaladores/calculadora/calculadora.component";
import { AsesoriaComponent } from "./components/instaladores/asesoria/asesoria.component";
import { CapacitacionComponent } from "./components/instaladores/capacitacion/capacitacion.component";
import { CreaproductoComponent } from "./components/adm/mercadeo/creaproducto/creaproducto.component";
import { BlogComponent } from "./components/blog/blog.component";
import { ArticuloComponent } from "./components/articulo/articulo.component";
import { ArticulopubComponent } from "./components/articulopub/articulopub.component";
import { QuienessomosComponent } from "./components/quienessomos/quienessomos.component";
import { PlacasComponent } from "./components/productos/placas/placas.component";
import { ResponsabilidadComponent } from "./components/responsabilidad/responsabilidad.component";
import { DistribuidorComponent } from "./components/adm/distribuidor/distribuidor.component";
import { InstaladorComponent } from "./components/adm/instalador/instalador.component";
import { AlmacenistaComponent } from "./components/adm/almacenista/almacenista.component";
import { AsesorComponent } from "./components/adm/asesor/asesor.component";
import { ClienteComponent } from "./components/adm/asesor/cliente/cliente.component";
import { ClientesComponent } from "./components/adm/asesor/clientes/clientes.component";
import { AsistenteComponent } from "./components/adm/asistente/asistente.component";
import { CursoComponent } from "./components/adm/asistente/curso/curso.component";
import { DashasiComponent } from "./components/adm/asistente/dash/dash.component";
import { CalidadComponent } from "./components/adm/calidad/calidad.component";
import { MercadeoComponent } from "./components/adm/mercadeo/mercadeo.component";
import { ArticuloaddComponent } from "./components/adm/mercadeo/articulo/articulo.component";
import { AsesoresComponent } from "./components/adm/mercadeo/asesores/asesores.component";
import { IndicadorAsesorComponent } from "./components/adm/mercadeo/indicador-asesor/indicador-asesor.component";
import { ArticulosComponent } from "./components/adm/mercadeo/articulos/articulos.component";
import { SliderComponent } from "./components/adm/mercadeo/slider/slider.component";
import { UsuariosComponent } from "./components/adm/mercadeo/usuarios/usuarios.component";
import { ModalUsuario } from "./components/adm/mercadeo/usuarios/usuarios.component";
import { DashdisComponent } from "./components/distribuidores/dash/dash.component";
import { DocumentosComponent } from "./components/distribuidores/documentos/documentos.component";
import { EstadisticaComponent } from "./components/distribuidores/estadistica/estadistica.component";
import { PedidosComponent } from "./components/distribuidores/pedidos/pedidos.component";
import { PqrsComponent } from "./components/distribuidores/pqrs/pqrs.component";
import { CursoiComponent } from "./components/instaladores/curso/curso.component";
import { AuthService } from "./services/auth.service";
import { ClickOutsideDirective } from "./components/distribuidores/registro/dropdown.directive";
import { LetterBoldPipe } from "./pipes/letter-bold.pipe";
import { SearchFilterPipe } from "./pipes/filter.pipe";

import { removeNgStyles, createNewHosts } from "@angularclass/hmr";
import { MasillaPRComponent } from "./components/productos/masillas/interiores/masilla-pr/masilla-pr.component";
import { ProductosComponent } from "./components/productos/productos.component";
// tslint:disable-next-line: max-line-length
import { MasillasFinishTeckComponent } from "./components/productos/masillas/interiores/masillas-finish-teck/masillas-finish-teck.component";
import { MasillasHighProComponent } from "./components/productos/masillas/interiores/masillas-high-pro/masillas-high-pro.component";
import { LightEstandarComponent } from "./components/productos/placas/panelrey/light-estandar/light-estandar.component";
import { GuardReyComponent } from "./components/productos/placas/panelrey/guard-rey/guard-rey.component";
import { FireReyComponent } from "./components/productos/placas/panelrey/fire-rey/fire-rey.component";
import { GlassReyComponent } from "./components/productos/placas/panelrey/glass-rey/glass-rey.component";
import { RegularComponent } from "./components/productos/placas/panelrey/regular/regular.component";
import { TiposdeestucoComponent } from "./components/blog/tiposdeestuco/tiposdeestuco.component";
import { MasillaparaparedesComponent } from "./components/blog/masillaparaparedes/masillaparaparedes.component";
import { MasillaparadrywallComponent } from "./components/blog/masillaparadrywall/masillaparadrywall.component";
// tslint:disable-next-line: max-line-length
import { DiferenciasentreestucoymasillaComponent } from "./components/blog/diferenciasentreestucoymasilla/diferenciasentreestucoymasilla.component";
import { CertificaciongreenguardComponent } from "./components/blog/certificaciongreenguard/certificaciongreenguard.component";
import { MatPaginatorIntl } from "@angular/material";
import { MiembrosComponent } from "./components/adm/miembros/miembros.component";
import { BusquedaComponent } from "./components/adm/busqueda/busqueda.component";
import { DashboardadminComponent } from "./components/adm/dashboardadmin/dashboardadmin.component";
import { ArticuloNuevoComponent } from "./components/adm/articulo-nuevo/articulo-nuevo.component";
import { UsuarioService } from "./services/user.service";
import { AdvancedComponent } from './components/productos/masillas/interiores/advanced/advanced.component';
import { BasickComponent } from './components/productos/masillas/interiores/basick/basick.component';
import { ProfessionalComponent } from './components/productos/masillas/interiores/professional/professional.component';
import { MaxComponent } from './components/productos/masillas/interiores/max/max.component';
import { InscripcionComponent } from './inscripcion/inscripcion.component';

@NgModule({
  entryComponents: [ModalUsuario],
  declarations: [
    AppComponent,
    HomeComponent,
    MainMenuComponent,
    UsuariosComponent,
    ModalUsuario,
    ArticulosComponent,
    AsesorComponent,
    CursoiComponent,
    PqrsComponent,
    PedidosComponent,
    ClienteComponent,
    ClientesComponent,
    AsistenteComponent,
    CursoComponent,
    SliderComponent,
    DashComponent,
    DashasiComponent,
    DashdisComponent,
    CalidadComponent,
    MercadeoComponent,
    ArticuloaddComponent,
    AsesoresComponent,
    IndicadorAsesorComponent,
    LoginComponent,
    GeneralComponent,
    MasillasComponent,
    InterioresComponent,
    ExterioresComponent,
    AdhesivosComponent,
    PinturasComponent,
    PlacasComponent,
    PanelreyComponent,
    PermabaseComponent,
    ProyectosComponent,
    DistribuidorComponent,
    DocumentosComponent,
    EstadisticaComponent,
    InstaladorComponent,
    AlmacenistaComponent,
    RegistroComponent,
    RegistroinsComponent,
    ResponsabilidadComponent,
    MapaComponent,
    CalculadoraComponent,
    AsesoriaComponent,
    CapacitacionComponent,
    CreaproductoComponent,
    BlogComponent,
    ArticuloComponent,
    ArticulopubComponent,
    QuienessomosComponent,
    ClickOutsideDirective,
    SearchFilterPipe,
    LetterBoldPipe,
    MasillaPRComponent,
    ProductosComponent,
    MasillasFinishTeckComponent,
    MasillasHighProComponent,
    LightEstandarComponent,
    GuardReyComponent,
    FireReyComponent,
    GlassReyComponent,
    RegularComponent,
    TiposdeestucoComponent,
    MasillaparaparedesComponent,
    MasillaparadrywallComponent,
    DiferenciasentreestucoymasillaComponent,
    CertificaciongreenguardComponent,
    MiembrosComponent,
    BusquedaComponent,
    DashboardadminComponent,
    ArticuloNuevoComponent,
    AdvancedComponent,
    BasickComponent,
    ProfessionalComponent,
    MaxComponent,
    InscripcionComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: "serverApp" }),
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    LayoutModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    MomentModule,
    LeafletModule.forRoot(),
    routing,
    AngularFileUploaderModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    EditorModule,
  ],
  providers: [
    { provide: TINYMCE_SCRIPT_SRC, useValue: "tynymce/timymce.min.js" },
    appRoutingProviders,
    AuthService,
    UsuarioService,
    OpenStreetMapProvider,
    { provide: MatPaginatorIntl, useValue: getSpanishPaginatorIntl() },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(public appRef: ApplicationRef) {}
  hmrOnDestroy(store) {
    let cmpLocation = this.appRef.components.map(
      (cmp) => cmp.location.nativeElement
    );
    // recreate elements
    store.disposeOldHosts = createNewHosts(cmpLocation);
    // remove styles
    removeNgStyles();
  }
  hmrAfterDestroy(store) {
    // display new elements
    store.disposeOldHosts();
    delete store.disposeOldHosts;
  }
}
