import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import * as alertify from 'alertifyjs';
import { AlertifyService } from '../../services/alertify.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  model: any = {};
  public pageTitle: string;

  constructor(private authService: AuthService,
    private alertify: AlertifyService,
    private router: Router) {
    this.pageTitle = "BIENVENIDO";
  }

  ngOnInit() { }

  login() {
    this.authService.login(this.model).subscribe(next => {
      this.alertify.success('Autenticado correctamente!');
    }, error => {
      this.alertify.error('Error de autenticacion!');
    }, () => {
      this.router.navigate(['/miembros'])
    });
  }
}
