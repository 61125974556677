import { Injectable } from '@angular/core';
import { Publicacion } from '../models/post';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Global } from './global';


@Injectable()
export class ArticuloService {
  public url: string;
  publicacions: Publicacion[];
  dialog: false;
  search: '';
  constructor(
    private http: HttpClient
  ) {
    this.url = Global.url;
  }
  tomarArticulos(): Observable<any> {
    const publications = 'publicacions/list';
    const header = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    return this.http.get(this.url + publications, { headers: header });
  }
  tomarArticulo(idPublication): Observable<any> {
    const publication = 'publicacions/mostrar/';
    return this.http.get(this.url + publication + idPublication);
  }
  busqueda(searchString): Observable<any> {
    const busqueda = 'publicacions/buscar/';
    return this.http.get(this.url + busqueda + searchString);
  }
  crear(publication: any): Observable<any> {
    const crear = 'publicacions/crearpublicaciones/';
    return this.http.post<any>(this.url + crear, publication);
  }
}
