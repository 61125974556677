import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-responsabilidad',
  templateUrl: './responsabilidad.component.html',
  styleUrls: ['./responsabilidad.component.css']
})
export class ResponsabilidadComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
