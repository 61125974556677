import { Component, OnInit, Input } from "@angular/core";
import { Publicacion } from "../../models/post";
import { ArticuloService } from "../../services/articulo.service";
import { Global } from "../../services/global";

@Component({
  selector: "app-blog",
  templateUrl: "./blog.component.html",
  styleUrls: ["./blog.component.css"],
  providers: [ArticuloService],
})
export class BlogComponent implements OnInit {
  // public publicacions: Array<Publicacion>;
  public url: string;

  @Input() publicacions: Publicacion[];
  constructor(private articuloService: ArticuloService) {
    this.url = Global.url;
  }

  ngOnInit() {
    this.obtenerPublicaciones();
  }
  obtenerPublicaciones() {
    this.articuloService.tomarArticulos().subscribe(
      (response) => {
        if (response.status === "success") {
          this.publicacions = response.publicacions;
        }
        this.publicacions = response;
      },
      (error) => {
        // console.log(error);
      }
    );
  }
}
