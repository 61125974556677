import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-light-estandar',
  templateUrl: './light-estandar.component.html',
  styleUrls: ['./light-estandar.component.css']
})
export class LightEstandarComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
